import * as React from 'react';
import { useState, useEffect } from 'react';

import {
    Box,
    Container,
    Typography,
    Paper,
    Stack,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import ecosistemas from '../../assets/images/ecosistemas.png'

import ContainerEcoOptions from '../components/ContainerEcoOptions';

import playa from '../../assets/images/playa.png';
import montana from '../../assets/images/montana.png';
import llano from '../../assets/images/llanos.png';
import ciudad from '../../assets/images/ciudad.png';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Data from '../../servicio/Data';
import AppBar from '@mui/material/AppBar';

import CustomCard from '../components/CustomCard'

const Img = styled('img')({
    margin: 0,
    width: '100%',
    minHeight: 'calc(100vh - 0px)',
    justifyContent: 'center',
    display: 'flex',
    position: 'relative',
    opacity: 0.2,
});

const ImageButton = styled(ToggleButton)(({ theme }) => ({
    position: 'relative',
    height: 100,
    [theme.breakpoints.down('sm')]: {
        width: '100% !important', // Overrides inline-style
        height: 100,
    },
    '&.MuiToggleButton-root.Mui-selected': {
        backgroundColor: 'black',
        boxShadow: `0 1px 3px 0`,
        transform: 'scale(1.001)',
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
    borderRadius: 10
});

const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
    borderRadius: 10
}));

const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
}));

const opciones = [
    {
        id: 'posadas',
        title: 'posadas',
    },
    {
        id: 'restaurant',
        title: 'Restaurant',
    },
    {
        id: 'lanchas',
        title: 'Lanchas',
    },
    {
        id: 'toldos',
        title: 'Toldos',
    },
    {
        id: 'paseos',
        title: 'Paseos',
    },
];

const images = [
    {
        id: 'playa',
        url: `${playa}`,
        title: 'Playa',
        width: '21%',
    },
    {
        id: 'montana',
        url: `${montana}`,
        title: 'Montaña',
        width: '21%',
    },
    {
        id: 'llano',
        url: `${llano}`,
        title: 'Llano',
        width: '21%',
    },
    {
        id: 'ciudad',
        url: `${ciudad}`,
        title: 'Ciudad',
        width: '21%',
    },
];

export default function Home(props) {

    const [opcioneco, setOpcionEco] = React.useState([]);
    //const [opcioneco, setOpcionEco] = React.useState(props.opciones !== undefined ? props.opciones[0].title : []);

    const [estatus, setEstatus] = useState(0)
    const [idActual, setidActual] = useState()
    const [idAnterior, setidAnterior] = useState()
    const [opciones, setOpciones] = useState()
    const [activo, setActivo] = useState(false)

    const useStyles = makeStyles(() => ({
        container: {
            maxWidth: '100%',
            paddingLeft: '0px',
            paddingRight: '0px',
        }
    }));

    const classes = useStyles();

    const [alignment, setAlignment] = React.useState(images[0].title);

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const handleChangeECO = (event, opcion) => {
        setOpcionEco(opcion);
        console.log(opcion);
    };

    const OnClickToggleEco = (idActual, idAnterior, estatus) => {

        console.log(idActual, idAnterior, estatus)
        setActivo(!activo);
        // switch (idActual) {
        //     case "playa":
        //         setOpciones(playas)
        //         break;
        //     case "montana":
        //         setOpciones(montanas)
        //         break;
        //     case "llano":
        //         setOpciones(llanos)
        //         break;
        //     case "ciudad":
        //         setOpciones(ciudades)
        //         break;
        //     default:
        //         setOpciones(ciudades)
        //         break;
        // }

        console.log(idActual, idAnterior, estatus, opciones)
        if (estatus === 1) {
            if (idActual === idAnterior) {
                setidActual(idActual)
                setidAnterior(idAnterior)
                return setEstatus(0)
            } else {
                setidActual(idActual)
                setidAnterior(idAnterior)
                return setEstatus(1)
            }
        } else {
            setidActual(idActual)
            setidAnterior(idActual)
            return setEstatus(1)
        }

    }

    useEffect(() => {
        Data
            .Opciones()
            .then(
                (response) => {
                    setOpciones(response);
                },
                error => {
                }
            );

    }, []);

    return (
        <Container
            className={classes.container}
            sx={{
                background: 'transparent',
                alignContent: 'center',
            }}
        >
            <Box
                sx={{
                    margin: 0,
                    width: '99.7vw',
                    minHeight: 'calc(100vh - 0px)',
                    position: 'relative',
                    display: 'inline-block',
                    textAlign: 'center',
                }}
            >
                <Container>
                    {/* <ContainerEcoOptions opciones={opciones} /> */}
                    <ToggleButtonGroup
                        value={alignment}
                        exclusive
                        aria-label="text alignment"
                        onChange={handleChange}
                        sx={{
                            width: '100%', display: 'flex', flexWrap: 'wrap', minWidth: 300, justifyContent: 'space-between',
                            margin: {
                                xs: '20px 0px 0px 0px',
                                sm: '20px 0px 0px 0px',
                                md: '2px 0px 0px 0px',
                                xl: '20px 0px 0px 0px',
                                lg: '20px 0px 0px 0px'
                            },
                            position: 'fixed',
                            zIndex: 1,
                            position: 'absolute',
                            top: '25%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            paddingLeft: '225px',
                            paddingRight: '225px'
                        }}
                    >
                        {images.map((image) => (
                            <ImageButton
                                value={image.title}
                                key={image.title}
                                style={{
                                    width: image.width,
                                    margin: {
                                        sm: '0px 20px 0px 20px',
                                        md: '0px 20px 0px 20px',
                                        xl: '0px 20px 0px 20px',
                                        lg: '0px 20px 0px 20px'
                                    },
                                    borderRadius: 10
                                }}
                                onClick={() => OnClickToggleEco(image.id, idActual, estatus)}
                            >
                                <ImageSrc style={{ backgroundImage: `url(${image.url})`, opacity: 0.8 }} />
                                <ImageBackdrop className="MuiImageBackdrop-root" />
                                <Image>
                                    <Typography
                                        component="span"
                                        variant="subtitle1"
                                        color="inherit"
                                        sx={{
                                            position: 'relative',
                                            p: 4,
                                            pt: 2,
                                            pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                        }}
                                    >
                                        {image.title}
                                        <ImageMarked className="MuiImageMarked-root" />
                                    </Typography>
                                </Image>

                            </ImageButton>

                        ))}
                    </ToggleButtonGroup>
                    <CustomCard />
                </Container>
                <Img src={ecosistemas} />
            </Box>
        </Container >
    );
}