import axios from "axios";

class ProviderService {

    crear = async (codigo_prestador, nombre, direccion, codigo_ciudad, clave, activo, codigo_pais, premium, ws, fb, ig, email, www, servicio1, servicio2, servicio3, etiqueta) => {                
        return axios.post(`${this.obtenerURL_API()}` + "/api/prestadores/", {
            codigo_prestador, nombre, direccion, codigo_ciudad, clave, activo, 
        codigo_pais, premium, ws, fb, ig, email, www, servicio1, servicio2, servicio3, etiqueta
        })
    }

    preregistro = async (data) => {                
        return axios.post(`${this.obtenerURL_API()}` + "/api/prestadores/preregistro", { data })
    }

    agregardatos = async (data) => {                         
        return axios.post(`${this.obtenerURL_API()}` + "/api/prestadores/agregardatos", { data })
    }

    consultar = async (codigo_prestador) => {                
        return axios.get(`${this.obtenerURL_API()}/api/prestadores/${codigo_prestador}`)
    }

    preregistro = async (data) => {                
        return axios.post(`${this.obtenerURL_API()}` + "/api/prestadores/preregistro", { data })
    }

    validarPrestador = async (data) => {                
        return axios.post(`${this.obtenerURL_API()}/api/prestadores/validar`, data)
    }

    consultar_promociones = async (codigo) => {                
        return axios.get(`${this.obtenerURL_API()}/api/prestadores_publicaciones/`,{
            params: {
                codigo_ciudad: codigo,
              }
        })
    }

    consultar_publicaciones_disponibles = async () => {                
        return axios.get(`${this.obtenerURL_API()}/api/ciudades/consultarconpublicacion/`)
    }

    consultar_publicaciones_disponibles_codigo = async (codigo) => {                
        return axios.get(`${this.obtenerURL_API()}/api/ciudades/consultarconpublicacion/`,{
            params: {
                codigo_ciudad: codigo,
              }
        })
    }

    consultar_promociones_por_codigo = async (codigo) => {                
        return axios.get(`${this.obtenerURL_API()}/api/prestadores_publicaciones/${codigo}`)
    }

    obtenerURL_API() { //PARA OBTENER URL EN ENTORNOS PRODUCTIVOS
        let url_principal = window.location.host;
        //console.log(url_principal);
        if (url_principal.toString().includes('localhost')) {
            return process.env.REACT_APP_URL_API_DEV
        } else {
            return process.env.REACT_APP_URL_API
        }
    }

}

export default new ProviderService();