import * as React from 'react';

import icInstgram from '../../assets/images/ic-instagram.png'
import icWWW from '../../assets/images/www.png'
import FloatingWhatsApp from 'react-floating-whatsapp'
import IconButton from '@mui/material/IconButton';

import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

import {
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";

const Img = styled('img')({
    margin: 0,
    height: '-webkit-fill-available',
    width: '100%',
    objectFit: 'cover',
    display: 'flex',
});

const obtenerIconRedes = (tipo) => {
    switch (tipo) {
        case 'ig':
            return icInstgram;
        case 'www':
            return icWWW;
    }
}

const redirigir = (tipo, cuenta) => {

    switch (tipo) {
        case 'ig':
            window.open('https://www.instagram.com/' + cuenta + '/');
            break;
        case 'www':
            window.open(cuenta);
            break;
    }
}

export default function SocialMedia(props) {

    const useStyles = makeStyles(() => ({
        floatingWhatsApp_450: {
            '& .styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE': {
                top: '30px',
                left: '-225px',
            }
        },
        floatingWhatsApp: {
            '& .styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE': {
                top: '30px',
                right: '30px',
            }
        },
    }));

    const classes = useStyles();

    return (
        <div>
            {props.comunicaciones.map((contacto) => (
                contacto.tipo === 'ws' ?
                    <IconButton aria-label="whatsapp">
                        <FloatingWhatsApp
                            className={props.m450 ? props.floatingWhatsApp_450 : props.floatingWhatsApp}
                            styles={{
                                position: 'initial',
                                width: 35,
                                height: 35,
                                fontSize: 12,
                                boxShadow: `0 3px 6px 0 `,
                            }}
                            phoneNumber={contacto.cuenta}
                            avatar={props.imagen}
                            accountName={props.nombre}
                            chatMessage={"Un placer saludarte en que te puedo ayudar?"}
                            placeholder="Escribe un mensaje"
                            statusMessage={`Disponible entre ${contacto.hora_inicio} ${contacto.ampm_inicio} y ${contacto.hora_fin} ${contacto.ampm_fin}`}
                        />
                    </IconButton>
                    : <IconButton
                        size="medium"
                        sx={{
                            paddingTop: 0,
                            paddingBottom: 0,
                        }}
                    >
                        <Img
                            src={obtenerIconRedes(contacto.tipo)}
                            WhatsappShareButton onClick={() => redirigir(contacto.tipo, contacto.cuenta)}
                            sx={{
                                objectFit: 'fill',
                            }}
                        />
                    </IconButton>
            ))}
            {/* <WhatsappShareButton
                url={'https://turismo123.com'}
                size={32}
                round={true}
                quote={'Prueba kjfksjfksdjfkjdfjf'}
            >
                <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton> */}
        </div>
    );
}