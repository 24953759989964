import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from '@mui/styles';

import GridContainer from "../../components/Provider/Grid/GridContainer.js";
import GridItem from "../../components/Provider/Grid/GridItem.js";

import Parallax from "../../components/Provider/Parallax/Parallax.js";

import Data from '../../../servicio/Data';

import ecosistemas from "../../../assets/images/ecosistemas.png"

import styles from "../../components/Provider/profilePage.js";
import Header from "../../components/Header.js";
import { useParams } from 'react-router-dom';
import FunctionsCommon from "../../functions/FunctionsCommon.js";
import EmblaCarousel from "../../components/EmblaCarousel/EmblaCarousel.js";

import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';

import CarouselTab from "../../components/CarouselTab.js";
import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Divider,
  List,
} from '@mui/material'
import PhotoCameraTwoToneIcon from '@mui/icons-material/PhotoCameraTwoTone';
import RoomServiceTwoToneIcon from '@mui/icons-material/RoomServiceTwoTone';
import CollectionsTwoToneIcon from '@mui/icons-material/CollectionsTwoTone';
import MapTab from "../../components/MapTab.js";

import ButtonBases from "../../components/Provider/CustomButtons/ButtonBase";
import CardServices from "../../components/Provider/Services/CardServices.js";
import PinDropTwoToneIcon from '@mui/icons-material/PinDropTwoTone';
import FactCheckTwoToneIcon from '@mui/icons-material/FactCheckTwoTone';
import Title from "../../components/Provider/Title/Title.js";
import ProviderItems from "../../components/Provider/Items/ProviderItems.js";
import SocialMediaSection from "../../components/Provider/SocialMedia/SocialMediaSection.js";

const FireNav = styled(List)({
  '& .MuiListItemButton-root': {
    paddingLeft: 24,
    paddingRight: 24,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
});

const Img = styled('img')({
  margin: 0,
  height: '-webkit-fill-available',
  width: '100%',
  height: '60vh',
  objectFit: 'cover',
  display: 'flex',
});

const useStyles = makeStyles(styles);

export default function ProviderProfile(props) {

  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  const params = useParams();
  let arreglo_url = [];
  arreglo_url = params.url_prestador.split('-');

  const [codigo_prestador, setCodigo_Prestador] = React.useState(arreglo_url[arreglo_url.length - 1]);
  const [prestador, setPrestador] = React.useState([]);

  let slides = [];

  React.useEffect(() => {
    Data
      .prestadorCodigo(codigo_prestador)
      .then(
        (response) => {
          console.log(response)
          setPrestador(response.data[0]);
          slides = Array.from(Array(response.data[0].publicaciones.length).keys())
        },
        error => {
        }
      );
  }, [])

  return (
    <div style={{ paddingBottom: 10 }}>
      <Header />
      <Parallax
        small
        filter
        image={ecosistemas}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridItem>
              <div className={classes.profile}>
                <div>
                  <img src={`${FunctionsCommon.obtenerImagenPrincipalJSON(prestador.codigo_prestador, prestador.imagenes)}`} alt="..." className={imageClasses} />
                </div>
                <div className={classes.name}>
                  <GridItem sx={{ textAlign: 'center' }}>
                    <h1 className={classes.title}>{prestador.nombre}</h1>
                    {/* <Button justIcon link className={classes.margin5}>
                    <i className={"fab fa-twitter"} />
                  </Button>
                  <Button justIcon link className={classes.margin5}>
                    <i className={"fab fa-instagram"} />
                  </Button>
                  <Button justIcon link className={classes.margin5}>
                    <i className={"fab fa-facebook"} />
                  </Button> */}
                  </GridItem>
                </div>
              </div>
              <GridItem sx={{ textAlign: 'center' }}>
                <SocialMediaSection
                  comunicaciones={prestador.comunicaciones}
                  codigo_prestador={prestador.codigo_prestador}
                  nombre={prestador.nombre}
                  imagen={prestador.imagenes}
                  url={window.location.href}
                />
              </GridItem>
            </GridItem>
            <GridContainer justify="center">
              {
                prestador.length !== 0 ?
                  <Title
                    titulo="Tipo de servicios"
                    icon={<RoomServiceTwoToneIcon sx={{ color: '#1DB2F5', }} />}
                  >
                    <GridItem sx={{ paddingTop: 2 }}>
                      <Grid container spacing={3}>
                        {
                          prestador.tipo_servicios.map((servicio) => (
                            servicio.codigo_servicio !== 0 && servicio.codigo_servicio !== 8 ?
                              <Grid item xs={12} sm={6} md={3}>
                                <CardServices codigo_servicio={servicio.codigo_servicio} nombre={servicio.nombre} />
                              </Grid> : null
                          ))
                        }
                      </Grid>
                    </GridItem>
                  </Title> : null
              }
              {
                <Title
                  titulo="Ofrece:"
                  icon={<FactCheckTwoToneIcon sx={{ color: '#1DB2F5' }} />}
                >
                  <ProviderItems items={prestador.items} />
                </Title>
              }
              <GridItem xs={12} sm={12} md={6} sx={{ paddingTop: 2 }}>
                <Title
                  titulo="Galería"
                  icon={<CollectionsTwoToneIcon sx={{ color: '#1DB2F5', }} />}
                >
                  {
                    prestador.length !== 0 ? <CarouselTab
                      imagenes={prestador.imagenes}
                      codigo_prestador={prestador.codigo_prestador}
                      tipo='fotos'
                    /> : null
                  }
                </Title>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} sx={{ paddingTop: 2 }}>
                <Title
                  titulo="Ubicación"
                  icon={<PinDropTwoToneIcon sx={{ color: '#1DB2F5', }} />}
                >
                  {
                    prestador.lat !== undefined && prestador.lat !== "0" && prestador.lat !== ""
                      ?
                      <MapTab
                        nombre={prestador.nombre}
                        imagen_mapa={`${FunctionsCommon.obtenerImagenPrincipalJSON(prestador.codigo_prestador, prestador.imagenes)}`}
                        lat={prestador.lat}
                        lon={prestador.lon}
                        direccion={prestador.direccion}
                        min_height={'60vh'}
                      />
                      :
                      <ButtonBases />
                  }
                </Title>
              </GridItem>
              <Divider />
              {
                prestador.length !== 0 ?
                  prestador.publicaciones.length !== 0 ?
                    <Title
                      titulo="Publicaciones destacadas"
                      icon={<LocalActivityTwoToneIcon sx={{ color: '#1DB2F5', }} />}
                    >
                      <EmblaCarousel
                        slides={slides.length !== 0 ? slides : []}
                        publicaciones={prestador !== undefined ? prestador.publicaciones : []}
                        codigo_prestador={codigo_prestador}
                        imagen_principal={`${FunctionsCommon.obtenerImagenPrincipalJSON(prestador.codigo_prestador, prestador.imagenes)}`}
                      />
                    </Title>
                    : null : null
              }
            </GridContainer>
            {/* <div className={classes.description}>
              <p>
                An artist of considerable range, Chet Faker — the name taken by
                Melbourne-raised, Brooklyn-based Nick Murphy — writes, performs
                and records all of his own music, giving it a warm, intimate
                feel with a solid groove structure.{" "}
              </p>
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>

              </GridItem>
            </GridContainer> */}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div >
  );
}
