import * as React from 'react';


import logo from '../../../assets/images/logo_circular2.png'
import logo_ecosistemas from '../../../assets/images/todo.png'
import '../../../css/style.css'

//import {Button_Normal, Button_Background, ButtonWOBackground} from  '../components/Button'
import Footer from '../../components/Footer'
import CustomCard from '../../components/CustomCard'
import CardShare from '../../components/CardShare'
//import TabBasic from  '../components/TabBasic'
//import Button_Image from  '../components/Button_Image'
import Search from '../../components/Search'
import Tabs from '../../components/Tabs'
import playa from '../../../assets/images/playa.png'
import ciudad from '../../../assets/images/ciudad.png'
import llanos from '../../../assets/images/llanos.png'
import montana from '../../../assets/images/montana.png'

import promo1 from '../../../assets/images/promo1.png'
import promo2 from '../../../assets/images/promo2.png'
import promo3 from '../../../assets/images/promo3.png'
import promo4 from '../../../assets/images/promo4.png'

import patanemo from '../../../assets/images/patanemo.png'
import ocumare from '../../../assets/images/ocumare.png'
import chichiriviche from '../../../assets/images/chichiriviche.png'
import coloniatovar from '../../../assets/images/coloniatovar.png'
import { Link } from "react-router-dom";

import {
    Box,
    Container,
    Card,
    CardContent,
    ButtonBase,
    CardActions,
    IconButton,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import ProviderService from '../../../servicio/ProviderService';
import CardProvider from '../../components/CardProvider';
import FunctionsCommon from '../../functions/FunctionsCommon'
import Header from '../../components/Header';

export function PostsByState(props) {
    const eslogan = "    ...turismo a la carta"

    const [promos_disponibles, setPromos_Disponibles] = React.useState([]);

    const useStyles = makeStyles(() => ({
        transicion: {
            transition: '0.3s',
            '&:hover': {
                transform: 'scale(1.02)',
            },
        },

    }));

    const classes = useStyles();

    React.useEffect(() => {
        ProviderService
            .consultar_publicaciones_disponibles()
            .then(
                (response) => {
                    console.log('data', response.data);
                    setPromos_Disponibles(response.data);
                },
                error => {
                }
            );
    }, [])

    return (
        <div>
            <Header />
            <div className="contenedor_principal">
                <Container>
                    <div className="contenedor_simple">
                        <h2>Viajes, full days y promociones destacadas por estado</h2>
                        <div className="contenedor_tarjetas_ciudades">
                            {promos_disponibles.map((promo) => (
                                <div className="contenedor_tarjetas_promociones">
                                    <Card className={classes.transicion} elevation={3} sx={{ maxHeight: 350, }}>
                                        <ButtonBase sx={{ width: '100%' }}>
                                            <Link to={{
                                                pathname: `/full-day-viajes-excursiones-en-venezuela/${promo.url}`
                                            }}>
                                                <img
                                                    className="contenedor_tarjetas_imagenes_ciudades"
                                                    src={`${FunctionsCommon.obtenerImagenDesdeItem(0, promo.nombre_imagen, '', '', 'Ciudades')}`}
                                                />
                                            </Link>
                                        </ButtonBase>
                                        <CardContent sx={{ paddingBottom: '7px !important' }}>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {promo.nombre}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </div>                    
                </Container>
            </div>
        </div>
    );
}
export default PostsByState;
