// import { Icon } from '@iconify/react';
// import androidFilled from '@iconify/icons-ant-design/android-filled';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
//import { fShortenNumber } from '../../../utils/formatNumber';

import DeckTwoToneIcon from '@mui/icons-material/DeckTwoTone';
import CommuteTwoToneIcon from '@mui/icons-material/CommuteTwoTone';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import HikingTwoToneIcon from '@mui/icons-material/HikingTwoTone';
import LuggageTwoToneIcon from '@mui/icons-material/LuggageTwoTone';
import DiningTwoToneIcon from '@mui/icons-material/DiningTwoTone';
import HotelTwoToneIcon from '@mui/icons-material/HotelTwoTone';
import VpnLockTwoToneIcon from '@mui/icons-material/VpnLockTwoTone';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';

const obtenerColor = (codigo) => {
    // buses: azul
    // paseos: verde oscuro
    // rest: amarillo
    // op tur: morado
    // hoteles: marron
    // seguros: rojo
    // toldos: rosado
    switch (codigo) {
        case 1:
            //MARRON
            return "RGB(212, 130, 26, 0.4)"
        case 2:
            //NARANJA 
            return "RGB(250, 129, 80, 0.4)"
        case 3:
            //VERDE  
            return "RGB(0, 138, 63, 0.4)"
        case 4:
            //MORADO
            return "RGB(167, 22, 76, 0.4)"
        case 5:
            //AZUL OSCURO
            return "RGB(0, 107, 217, 0.4)"
        case 6:
            //ROSADO
            return "RGB(255, 103, 177, 0.4)"
        case 7:
            //ROJO
            return "RGB(244, 67, 54, 0.4)"
        case 8:
            //AMARILLO DESTACADOS
            return "RGB(255, 183, 25, 0.4)"
    }
}

const obtenerIcon = (codigo) => {
    switch (codigo) {
        case 0:
            return <BallotTwoToneIcon fontSize='large' />
        case 1:
            return <HotelTwoToneIcon fontSize='large' />
        case 2:
            return <DiningTwoToneIcon fontSize='large' />
        case 3:
            return <HikingTwoToneIcon fontSize='large' />
        case 4:
            return <LuggageTwoToneIcon fontSize='large' />
        case 5:
            return <CommuteTwoToneIcon fontSize='large' />
        case 6:
            return <DeckTwoToneIcon fontSize='large' />
        case 7:
            return <VpnLockTwoToneIcon fontSize='large' />
        case 8:
            return <StarTwoToneIcon fontSize='large' />
    }
}

const TOTAL = 714000;

export default function CardServices(props) {

    // ----------------------------------------------------------------------

    const RootStyle = styled(Card)(({ theme }) => ({
        boxShadow: 'none',
        textAlign: 'center',
        padding: theme.spacing(1, 0),
        color: theme.palette.primary.dark,
        backgroundColor: obtenerColor(props.codigo_servicio),
        transition: '0.2s',
        '&:hover': {
            transform: 'scale(1.02)',
        },  
    }));

    const IconWrapperStyle = styled('div')(({ theme }) => ({
        margin: 'auto',
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        width: theme.spacing(8),
        height: theme.spacing(8),
        justifyContent: 'center',        
        color: theme.palette.primary.dark,
        backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
            theme.palette.primary.dark,
            0.5
        )} 100%)`
    }));

    // ----------------------------------------------------------------------

    return (
        <RootStyle>
            <IconWrapperStyle>
                {obtenerIcon(props.codigo_servicio)}
            </IconWrapperStyle>
            {/* <Typography variant="h3">{props.nombre}</Typography> */}
            <Typography variant="subtitle4" sx={{ opacity: 0.72 }}>
                {props.nombre}
            </Typography>
        </RootStyle>
    );
}
