import * as React from 'react';

//import '../../../css/style.css'
import '../../../css/ProvidersByCityAndService.css'
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { Link } from "react-router-dom";

import {
    Container,
    Card,
    Button,
    ButtonBase,
    CardActions,
    Typography,
    SnackbarContent,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

import ProviderService from '../../../servicio/ProviderService';
import DataService from '../../../servicio/Data';
import FunctionsCommon from '../../functions/FunctionsCommon'
import Header from '../../components/Header';

import { useParams } from 'react-router-dom';
import CardProviderByCityAndService from '../../components/CardProviderByCityAndService';

export function ProvidersByCty(props) {
    const eslogan = "    ...turismo a la carta"

    const params = useParams();

    //PRESTADORES - SERVICIOS
    const [hoteles, setHoteles] = React.useState([]);
    const [restaurantes, setRestaurantes] = React.useState([]);
    const [paseos, setPaseos] = React.useState([]);
    const [excursiones, setExcursiones] = React.useState([]);
    const [buses, setBuses] = React.useState([]);
    const [toldos, setToldos] = React.useState([]);
    const [seguros, setSeguros] = React.useState([]);

    const [tipo_servicio, setTipo_Servicio] = React.useState('');

    // const useStyles = makeStyles(() => ({
    //     transicion: {
    //         transition: '0.3s',
    //         '&:hover': {
    //             transform: 'scale(1.02)',
    //         },
    //     },

    // }));

    const useStyles = makeStyles(() => ({
        transicion: {
            transition: '0.5s',
            '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: '11px 10px 13px -6px rgba(0,0,0,0.73)',
                webkitBoxShadow: '11px 10px 13px -6px rgba(0,0,0,0.73)',
                mozBoxShadow: '11px 10px 13px -6px rgba(0,0,0,0.73)'
            },
        },
        root: {
            overflow: 'initial',
            maxWidth: 304,
            backgroundColor: 'transparent',
        },
        title: {
            marginBottom: 0,
        },
        rateValue: {
            fontWeight: 'bold',
            marginTop: 2,
        },
        content: {
            position: 'relative',
            padding: 24,
            margin: '-24% 16px 0',
            backgroundColor: '#fff',
            borderRadius: 4,
        },
        favorite: {
            position: 'absolute',
            top: 12,
            right: 12,
        },
        locationIcon: {
            marginRight: 4,
            fontSize: 18,
        },
    }));

    const classes = useStyles();

    React.useEffect(() => {

        let arreglo_url = [];
        let codigo_ciudad = '';
        let servicios = [];
        let codigo_estado = '';

        if (params.tipo_servicio !== undefined) {
            setTipo_Servicio(params.tipo_servicio)            
        }

        arreglo_url = params.url.split('en');
        servicios = arreglo_url[0].split('-');

        let serv = [];
        for (let i = 0; i < servicios.length; i++) {
            if (servicios[i].length !== 0) {
                serv.push(servicios[i])
            }
        }
        
        if (arreglo_url.length == 2) {
            codigo_ciudad = arreglo_url[1].replace('-', ' ');

            codigo_ciudad = codigo_ciudad.toString().replace(/-/gi, ' ')

        } else if (arreglo_url.length > 2) {
            for (let i = 1; i < arreglo_url.length; i++) {
                codigo_ciudad += arreglo_url[i].toString();
            }

            codigo_ciudad = codigo_ciudad.toString().replace(/--/gi, ' en ').replace('-', '');

        }

        DataService
            .ciudad_por_codigo(codigo_ciudad)
            .then(
                (response) => {                    
                    codigo_estado = response.data.codigo_estado;

                    let response_prestadores = [];
                    let response_servicios = [];
                    DataService
                        .servicios()
                        .then(
                            (response_serv) => {
                                response_servicios = response_serv.data

                                let servicios_ = [];

                                let index;

                                if (params.tipo_servicio !== undefined) {
                                    let parametro = params.tipo_servicio.replace(/-/gi, ' ')                                    
                                    for (let j = 0; j < response_serv.data.length; j++) {
                                        index = response_serv.data[j].nombre.toString().toLowerCase().indexOf(parametro);
                                        if (index !== -1) {
                                            servicios_.push(response_serv.data[j].codigo_servicio)
                                            break;
                                        }
                                    }
                                } else {
                                    for (let i = 0; i < serv.length; i++) {
                                        for (let j = 0; j < response_serv.data.length; j++) {
                                            index = response_serv.data[j].nombre.toString().toLowerCase().indexOf(serv[i]);
                                            if (index !== -1) {
                                                servicios_.push(response_serv.data[j].codigo_servicio)
                                            }
                                        }
                                    }
                                }

                                const result = servicios_.reduce((acc, item) => {
                                    if (!acc.includes(item)) {
                                        acc.push(item);
                                    }
                                    return acc;
                                }, [])

                                servicios_ = result;

                                let datos = [];
                                datos.push({
                                    codigo_pais: 'VEN',
                                    codigo_estado: codigo_estado,
                                    codigo_ciudad: codigo_ciudad.toString().trim(),
                                    busqueda: '',
                                    codigo_servicio: servicios_
                                })
                                
                                DataService
                                    .consultaAmpliada_porservicios(datos[0])
                                    .then(
                                        (response) => {                                            
                                            response_prestadores = response.data
                                            for (let k = 0; k < servicios_.length; k++) {
                                                for (let j = 0; j < response_prestadores.length; j++) {
                                                    for (let i = 1; i < response_prestadores[j].tipo_servicios.length; i++) {
                                                        if (parseInt(servicios_[k]) === parseInt(response_prestadores[j].tipo_servicios[i].codigo_servicio)) {
                                                            switch (servicios_[k]) {
                                                                case 1:
                                                                    setHoteles(hoteles => [...hoteles, response_prestadores[j]]);
                                                                    break;
                                                                case 2:
                                                                    setRestaurantes(restaurantes => [...restaurantes, response_prestadores[j]]);
                                                                    break;
                                                                case 3:
                                                                    setPaseos(paseos => [...paseos, response_prestadores[j]]);
                                                                    break;
                                                                case 4:
                                                                    setExcursiones(excursiones => [...excursiones, response_prestadores[j]]);
                                                                    break;
                                                                case 5:
                                                                    setBuses(buses => [...buses, response_prestadores[j]]);
                                                                    break;
                                                                case 6:
                                                                    setToldos(toldos => [...toldos, response_prestadores[j]]);
                                                                    break;
                                                                case 7:
                                                                    setSeguros(seguros => [...seguros, response_prestadores[j]]);
                                                                    break;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                        },
                                        error => {                                            
                                        }
                                    );
                            },
                            error => {                                
                            }
                        );
                },
                error => {                    
                }
            );
    }, [])

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: purple[500],
        '&:hover': {
            backgroundColor: purple[700],
        },
    }));

    return (
        <div>
            <Header />
            <div className="contenedor_principal">
                <Container>

                    {hoteles.length !== 0 ?
                        <CardProviderByCityAndService
                            titulo={'Hoteles y posadas'}
                            codigo_servicio={1}
                            url={params.url}
                            tipo_servicio={'hoteles-y-posadas'}
                            prestadores={hoteles}
                        /> : null}

                    {restaurantes.length !== 0 ?
                        <CardProviderByCityAndService
                            titulo={'Restaurantes'}
                            codigo_servicio={2}
                            url={params.url}
                            tipo_servicio={'restaurantes'}
                            prestadores={restaurantes}
                        /> : null}

                    {paseos.length !== 0 ?
                        <CardProviderByCityAndService
                            titulo={'Paseos y tours en sitio'}
                            codigo_servicio={3}
                            url={params.url}
                            tipo_servicio={'paseos-y-tours-en-sitio'}
                            prestadores={paseos}
                        /> : null}

                    {excursiones.length !== 0 ?
                        <CardProviderByCityAndService
                            titulo={'Excursiones y viajes'}
                            codigo_servicio={4}
                            url={params.url}
                            tipo_servicio={'excursiones-y-viajes'}
                            prestadores={excursiones}
                        /> : null}

                    {buses.length !== 0 ?
                        <CardProviderByCityAndService
                            titulo={'Buses y embarcaciones'}
                            codigo_servicio={5}
                            url={params.url}
                            tipo_servicio={'buses-y-embarcaciones'}
                            prestadores={buses}
                        /> : null}

                    {toldos.length !== 0 ?
                        <CardProviderByCityAndService
                            titulo={'Toldos'}
                            codigo_servicio={6}
                            url={params.url}
                            tipo_servicio={'toldos'}
                            prestadores={toldos}
                        /> : null}

                    {seguros.length !== 0 ?
                        <CardProviderByCityAndService
                            titulo={'Seguros de viaje'}
                            codigo_servicio={7}
                            url={params.url}
                            tipo_servicio={'seguros-de-viaje'}
                            prestadores={seguros}
                        /> : null}

                </Container>
            </div >
        </div >
    );
};

export default ProvidersByCty;
