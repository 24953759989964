import React,
{
    useEffect,
    useCallback,
    useRef
}
    from "react";

import { useParams } from "react-router-dom";

import useEmblaCarousel from "embla-carousel-react";
import Autoplay from 'embla-carousel-autoplay'
// import {
//     mediaByIndex
// } from "../Components/media/index.js";
import "../../../css/embla.css";

import FunctionsCommon from "../../functions/FunctionsCommon";

import {
    ButtonBase,
    Card,
    CardActions,
    Divider,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';

import { styled } from '@mui/material/styles';
import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';
import CustomCard from "../Provider/CustomCard/CustomCard";

const FireNav = styled(List)({
    '& .MuiListItemButton-root': {
        paddingLeft: 24,
        paddingRight: 24,
    },
    '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 16,
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
});

const redirigir = (tipo, cuenta) => {

    switch (tipo) {
        case 'ig':
            window.open('https://www.instagram.com/' + cuenta + '/');
            break;
        case 'www':
            window.open(cuenta);
            break;
    }
}

const EmblaCarousel = (props) => {

    const params = useParams();

    const autoplay = useRef(
        Autoplay(
            { delay: 1000, stopOnInteraction: false },
            (emblaRoot) => emblaRoot.parentElement
        )
    );

    const options = {
        loop: true,
    }

    //const [viewportRef, embla] = useEmblaCarousel(options, [autoplay.current]);
    const [viewportRef, embla] = useEmblaCarousel({
        loop: true,
        dragFree: false,
        containScroll: "trimSnaps"
    });

    const rafId = useRef(0); // requestAnimationFrame ID

    const animate = useCallback(() => {
        if (!embla || !rafId.current) return;

        const engine = embla.internalEngine();
        engine.location.add(-0.2);
        engine.target.set(engine.location);
        engine.scrollLooper.loop(-1);
        engine.slideLooper.loop();
        engine.translate.to(engine.location);
        rafId.current = requestAnimationFrame(animate);

    }, [embla, viewportRef]);

    const startAutoScroll = useCallback(() => {
        rafId.current = requestAnimationFrame(animate);
    }, [animate]);

    const stopAutoScroll = useCallback(() => {
        rafId.current = cancelAnimationFrame(rafId.current) || 0;
    }, []);

    useEffect(() => {
        if (!embla) return;

        embla.on("pointerDown", stopAutoScroll);
        embla.on("settle", startAutoScroll);

        startAutoScroll();
        return () => stopAutoScroll();
    }, [embla, startAutoScroll, stopAutoScroll]);

    //console.log(props.publicaciones);

    return (
        <Paper elevation={0} sx={{ maxWidth: '870px', marginLeft: 'auto', marginRight: 'auto', width: '100%' }}>
            <div className="embla">
                <div className="embla__viewport" ref={viewportRef}>
                    <div className="embla__container">
                        {props.publicaciones !== undefined ? props.publicaciones.map((publicacion, index) => (
                            <div className="embla__slide" key={index}>
                                <div className="embla__slide__inner">
                                    {/* <Card elevation={3} sx={{ maxHeight: 300 }} className="card_carousel">
                                        <ButtonBase sx={{ width: '100%' }} onClick={() => redirigir('www', `/perfil/${params.url_prestador}/publicacion/${publicacion.url}`)}>
                                            <Link>
                                                <img
                                                    className="contenedor_tarjetas_imagenes_promociones"
                                                    src={`${FunctionsCommon.obtenerImagenDesdeItem(props.codigo_prestador, publicacion.nombre_imagen, 'promociones', publicacion.tipo_imagen, 'Prestadores')}`}
                                                />
                                            </Link>
                                        </ButtonBase>
                                        <CardActions disableSpacing sx={{ background: '#13A20A' }}>
                                            <IconButton aria-label="agregar a favoritos">
                                                <FavoriteIcon />
                                            </IconButton>
                                            <IconButton aria-label="compartir">
                                                <ShareIcon />
                                            </IconButton>
                                        </CardActions>
                                    </Card> */}
                                    <CustomCard
                                        imagen={`${FunctionsCommon.obtenerImagenDesdeItem(props.codigo_prestador, publicacion.nombre_imagen, 'promociones', publicacion.tipo_imagen, 'Prestadores')}`}
                                        imagen_principal={props.imagen_principal}
                                        nombre={publicacion.descripcion.toString().toUpperCase()}
                                        latestPostLarge={false}
                                        latestPost={false}
                                        url_prestador={publicacion.url}
                                    />
                                    {/* <PlaneTicketCardDemo
                                    ciudad_origen={publicacion.ciudad_origen}
                                    codigo_ciudad={publicacion.codigo_ciudad}
                                    precio={publicacion.precio}
                                    imagen_principal={`${FunctionsCommon.obtenerImagenDesdeItem(props.codigo_prestador, publicacion.nombre_imagen, 'publicaciones', publicacion.tipo_imagen, 'Prestadores')}`}
                                /> */}
                                </div>
                            </div>
                        )) : null}
                    </div>
                </div>
            </div>
        </Paper>

    );
};

export default EmblaCarousel;
