import React from 'react';
// material
import {
    Avatar,
    Box,
    Dialog,
    DialogTitle,
    Fab,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText
} from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';

import FloatingWhatsApp from 'react-floating-whatsapp'
import FunctionsCommon from '../../../functions/FunctionsCommon';
import { styled } from '@mui/material/styles';

import icInstgram from '../../../../assets/images/ic-instagram.png'
import icWWW from '../../../../assets/images/www.png'
import ShareTwoToneIcon from '@mui/icons-material/ShareTwoTone';
import { blue, green } from '@mui/material/colors';

// ---------------------------------------------------------------------
import {
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    TelegramShareButton,
} from "react-share";
import {
    FacebookShareCount,
    HatenaShareCount,
    OKShareCount,
    PinterestShareCount,
    RedditShareCount,
    TumblrShareCount,
    VKShareCount
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    WhatsappIcon,
    TelegramIcon
} from "react-share";
// ----------------------------------------------------------------------

const Img = styled('img')({
    margin: 0,
    height: '-webkit-fill-available',
    width: '100%',
    objectFit: 'cover',
    display: 'flex',
});

export default function SocialMedia(props) {

    const [open, setOpen] = React.useState(false);

    const obtenerIconRedes = (tipo) => {
        switch (tipo) {
            case 'ig':
                return icInstgram;
            case 'www':
                return icWWW;
        }
    }

    const redirigir = (tipo, cuenta) => {

        switch (tipo) {
            case 'ig':
                window.open('https://www.instagram.com/' + cuenta + '/');
                break;
            case 'www':
                window.open(cuenta);
                break;
        }
    }

    const openModal = () => {
        setOpen(!open);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Box gridColumn="span 7" sx={{ paddingBottom: 0, width: '100%' }}>
            {props.comunicaciones !== undefined ? props.comunicaciones.map((contacto) => (
                contacto.tipo === 'ws' ?
                    <IconButton aria-label="whatsapp">
                        <FloatingWhatsApp
                            styles={{
                                position: 'initial',
                                width: 35,
                                height: 35,
                                fontSize: 12,
                                boxShadow: `0 3px 6px 0 `,
                            }}
                            phoneNumber={contacto.cuenta}
                            avatar={`${FunctionsCommon.obtenerImagenPrincipalJSON(props.codigo_prestador, props.imagen)}`}
                            accountName={props.nombre}
                            chatMessage={"Un placer saludarte en que te puedo ayudar?"}
                            placeholder="Escribe un mensaje"
                            statusMessage={`Disponible entre ${contacto.hora_inicio} ${contacto.ampm_inicio} y ${contacto.hora_fin} ${contacto.ampm_fin}`}
                        />
                    </IconButton>
                    : <IconButton
                        size="medium"
                        sx={{
                            paddingTop: 0,
                            paddingBottom: 0,
                        }}
                    >
                        <Img
                            src={obtenerIconRedes(contacto.tipo)}
                            onClick={() => redirigir(contacto.tipo, contacto.cuenta)}
                            sx={{
                                objectFit: 'fill',
                            }}
                        />
                    </IconButton>
            )) : null}
            {/* <IconButton aria-label="faccebook">
                                <FacebookOutlinedIcon sx={{ fontSize: 35 }} style={{ color: '#334C84' }} />
                            </IconButton>
                            <IconButton aria-label="location">
                                <PersonPinCircleTwoToneIcon sx={{ fontSize: 35 }} style={{ color: '#001E3C' }} />
                            </IconButton> */}
            <Fab
                size="small"
                aria-label="shared"
                sx={{
                    color: 'common.white',
                    bgcolor: green[500],
                    '&:hover': {
                        bgcolor: green[600],
                    }
                }
                }
                onClick={() => openModal()}
            >
                <ShareTwoToneIcon />
            </Fab>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Compartir URL</DialogTitle>
                <List sx={{ pt: 0, width: '100%', display: 'contents' }}>
                    <FacebookShareButton url={props.url} onClick={() => handleClose()}>
                        <ListItem button sx={{ width: 250 }}>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                                    <FacebookIcon size={32} round={true} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Facebook" />
                        </ListItem>
                    </FacebookShareButton>
                    <WhatsappShareButton url={props.url} onClick={() => handleClose()}>
                        <ListItem button sx={{ width: 250 }}>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                                    <WhatsappIcon size={32} round={true} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Whatsapp" />
                        </ListItem>
                    </WhatsappShareButton>
                    <TelegramShareButton url={props.url} onClick={() => handleClose()}>
                        <ListItem button sx={{ width: 250 }}>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                                    <TelegramIcon size={32} round={true} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Telegram" />
                        </ListItem>
                    </TelegramShareButton>

                    {/* {props.comunicaciones.map((contacto) => (
                        <ListItem button>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                                    <PersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText />
                        </ListItem>
                    ))} */}
                </List>
            </Dialog>
        </Box >
    );
}
