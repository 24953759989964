import { MapControl, withLeaflet } from "react-leaflet";
import L from "leaflet";

import '../../css/legend_map.css'

import marker from '../../assets/images/marker.png'

let direccion;

class LegendMap extends MapControl {
    createLeafletElement(props) { direccion = props.direccion }

    componentDidMount() {
        // get color depending on population density value
        const getColor = d => {
            return d > 1000
                ? "#800026"
                : d > 500
                    ? "#BD0026"
                    : d > 200
                        ? "#E31A1C"
                        : d > 100
                            ? "#FC4E2A"
                            : d > 50
                                ? "#FD8D3C"
                                : d > 20
                                    ? "#FEB24C"
                                    : d > 10
                                        ? "#FED976"
                                        : "#FFEDA0";
        };

        const legend = L.control({ position: "bottomright" });

        legend.onAdd = () => {
            const div = L.DomUtil.create("div", "info legend");
            const grades = [0, 10, 20, 50, 100, 200, 500, 1000];
            let labels = [];
            let from;
            let to;
        
            labels.push(
                '<i style="background:' +
                getColor(500 + 1) +
                '"></i> ' + direccion
            );
            // labels.push('<ul class="list-group">' +
            //     '<li class="list-group-item list-group-item-info">Second item</li>' +
            //     '</ul>');
            //            labels.push(item(direccion));

            div.innerHTML = labels.join("<br>");
            return div;
        };

        const { map } = this.props.leaflet;
        legend.addTo(map);
    }
}

export default withLeaflet(LegendMap);
