import * as React from 'react'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ws_image from '../../assets/images/whatsapp.png'
import ig_image from '../../assets/images/facebook.png'
import fb_image from '../../assets/images/ic-instagram.png'
import page_image from '../../assets/images/page.png'
import email_image from '../../assets/images/email.png'
import { styled, alpha } from '@mui/material/styles';
import { IconButton, Box, Button, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

import PhotoCamera from '@mui/icons-material/PhotoCamera';
import UploadImagesService from '../../servicio/UploadImagesService';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

import {
  Autocomplete,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Img = styled('img')({
  margin: 0,
  height: '-webkit-fill-available',
  width: '100%',
  objectFit: 'cover',
  display: 'flex',
});

const Input = styled('input')({
  display: 'none',
});

const steps = [
  {
    id: 1,
    label: 'Datos básicos',
    description: `Datos básicos`,
  },
  {
    id: 2,
    label: 'Tipo de prestador de servicio',
    description:
      'Tipo de prestador de servicio',
  },
  {
    id: 3,
    label: 'Comunicaciones',
    description: `Comunicaciones`,
  },
  {
    id: 4,
    label: 'Servicios',
    description: `Servicios`,
  },
  {
    id: 5,
    label: 'Etiquetas',
    description: `Etiquetas`,
  },
];

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
  '& .css-1vykg5s-MuiFormControl-root-MuiTextField-root': {
    width: '100%'
  },
  '& .css-1voyu5p': {
    width: '100%'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(TextField)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

export function DataBasicProvider(props) {
  const { changeHandler, onKeyDown } = props

  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '100%' },
      }}
      noValidate
      autoComplete="off"
    >
      <Search sx={{ visibility: props.campo_visible ? 'visible' : 'hidden', height: props.campo_visible ? 'auto' : 0 }}>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          name="codigo_prestador"
          value={props.codigo_prestador}
          type="number"
          placeholder="Buscar prestador"
          inputProps={{ 'aria-label': 'search' }}
          onChange={changeHandler}
          disabled={props.desactivar_busqueda}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              onKeyDown(event.target.value);
            }
          }}
        />
      </Search>
      <TextField
        required
        autoFocus
        hiddenLabel
        id="nombre"
        name="nombre"
        label="Nombre"
        type="text"
        fullWidth
        inputProps={{ maxLength: 50 }}
        value={props.nombre}
        onChange={changeHandler}
        disabled={props.desactivar_campos}
      />
      <TextField
        required
        value={props.direccion}
        id="direccion"
        name="direccion"
        label="Direccion"
        type="text"
        fullWidth
        inputProps={{ maxLength: 100 }}
        onChange={changeHandler}
        disabled={props.desactivar_campos}
      />
      <TextField
        required
        value={props.ciudad}
        id="ciudad"
        name="ciudad"
        label="Ciudad"
        type="text"
        fullWidth
        inputProps={{ maxLength: 50 }}
        onChange={changeHandler}
        disabled={props.desactivar_campos}
      />
      <TextField
        required
        value={props.municipio}
        id="municipio"
        name="municipio"
        label="Municipio"
        type="text"
        fullWidth
        inputProps={{ maxLength: 50 }}
        onChange={changeHandler}
        disabled={props.desactivar_campos}
      />
      <TextField
        required
        value={props.estado}
        id="estado"
        name="estado"
        label="Estado"
        type="text"
        fullWidth
        inputProps={{ maxLength: 50 }}
        onChange={changeHandler}
        disabled={props.desactivar_campos}
      />
      <TextField
        required
        value={props.pais}
        id="pais"
        name="pais"
        label="Pais"
        type="text"
        fullWidth
        inputProps={{ maxLength: 10 }}
        onChange={changeHandler}
        disabled={props.desactivar_campos}
      />
    </Box>
  )
}
export function SocialMediaProvider(props) {
  const { changeHandler } = props
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '100%' },
      }}
      noValidate
      autoComplete="off"
    >
      <List>
        <ListItem>
          <IconButton aria-label="ws"
            size="small"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Img
              alt="ws"
              src={ws_image}
              sx={{
                objectFit: 'fill',
              }}
            />
          </IconButton>
          <TextField
            height="10px"
            required
            autoFocus
            id="ws"
            name="ws"
            type="text"
            fullWidth
            inputProps={{ maxLength: 50 }}
            value={props.ws}
            onChange={changeHandler}
          />
        </ListItem>
        <ListItem>
          <IconButton aria-label="instagram"
            size="small"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Img
              alt="ws"
              src={ig_image}
              sx={{
                objectFit: 'fill',
              }}
            />
          </IconButton>
          <TextField
            id="fb"
            name="fb"
            type="text"
            fullWidth
            inputProps={{ maxLength: 50 }}
            value={props.fb}
            onChange={changeHandler}
          />
        </ListItem>
        <ListItem>
          <IconButton aria-label="fb"
            size="small"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Img
              alt="ws"
              src={fb_image}
              sx={{
                objectFit: 'fill',
              }}
            />
          </IconButton>
          <TextField
            id="ig"
            name="ig"
            type="text"
            fullWidth
            inputProps={{ maxLength: 50 }}
            value={props.ig}
            onChange={changeHandler}
          />
        </ListItem>
        <ListItem>
          <IconButton aria-label="page"
            size="small"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Img
              alt="ws"
              src={page_image}
              sx={{
                objectFit: 'fill',
              }}
            />
          </IconButton>
          <TextField
            id="www"
            name="www"
            type="text"
            fullWidth
            inputProps={{ maxLength: 50 }}
            value={props.www}
            onChange={changeHandler}
          />
        </ListItem>
        <ListItem>
          <IconButton aria-label="email"
            size="small"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Img
              alt="email"
              src={email_image}
              sx={{
                objectFit: 'fill',
              }}
            />
          </IconButton>
          <TextField
            id="email"
            name="email"
            type="text"
            fullWidth
            inputProps={{ maxLength: 50 }}
            value={props.email}
            onChange={changeHandler}
          />
        </ListItem>

      </List>
    </Box>
  )
}
export function TypeProvider(props) {
  const { changeHandlerChekbox } = props
  return (
    <div>
      <FormGroup>
        <FormControlLabel name="hotel" control={<Checkbox checked={props.tipoprestador.hotel} onChange={changeHandlerChekbox} />} label="Hotel y posada" />
        <FormControlLabel name="restaurant" control={<Checkbox checked={props.tipoprestador.restaurant} onChange={changeHandlerChekbox} />} label="Restaurant" />
        <FormControlLabel name="paseos" control={<Checkbox checked={props.tipoprestador.paseos} />} onChange={changeHandlerChekbox} label="Paseos" />
        <FormControlLabel name="operador" control={<Checkbox checked={props.tipoprestador.operador} />} onChange={changeHandlerChekbox} label="Operador turistico" />
        <FormControlLabel name="buses" control={<Checkbox checked={props.tipoprestador.buses} />} onChange={changeHandlerChekbox} label="Buses y embarcaciones" />
        <FormControlLabel name="toldos" control={<Checkbox checked={props.tipoprestador.toldos} />} onChange={changeHandlerChekbox} label="Toldos" />
        <FormControlLabel name="seguros" control={<Checkbox checked={props.tipoprestador.seguros} />} onChange={changeHandlerChekbox} label="Seguros de viaje" />
      </FormGroup>
    </div>
  )
}
export function ServicesProvider(props) {
  const { changeHandler } = props
  return (
    <Box>
      <List>
        <ListItem>
          <TextField
            required
            autoFocus
            id="servicio1"
            name="servicio1"
            label="Servicio 1"
            type="text"
            fullWidth
            inputProps={{ maxLength: 300 }}
            value={props.servicio1}
            onChange={changeHandler}
          />
        </ListItem>
        <ListItem>
          <TextField
            required
            id="servicio2"
            name="servicio2"
            label="Servicio 2"
            type="text"
            fullWidth
            inputProps={{ maxLength: 300 }}
            value={props.servicio2}
            onChange={changeHandler}
          />
        </ListItem>
        <ListItem>
          <TextField
            id="servicio3"
            name="servicio3"
            label="Servicio 3"
            type="text"
            fullWidth
            inputProps={{ maxLength: 300 }}
            value={props.servicio3}
            onChange={changeHandler}
          />
        </ListItem>
      </List>
    </Box>
  );
}
export function ImagesProvider(props) {
  const { changeHandler, uploadFiles, removeImage } = props

  const [checked, setChecked] = React.useState([1]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <Box sx={{ paddingBottom: 2 }}>
      <label htmlFor="contained-button-file">
        <Input name="imagenes" accept="image/*" id="contained-button-file" multiple type="file" onInputCapture={changeHandler} />
        <IconButton color="primary" aria-label="upload picture" component="span">
          <PhotoCamera />
        </IconButton>
        {/* <Button variant="contained" component="span">
          Fotos
        </Button> */}
      </label>
      <List dense sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper' }}>
        {props.vista_previa.length !== 0 ? props.vista_previa.map((imagen, index) => {
          return (
            <ListItem
              key={imagen + index}
              secondaryAction={
                <IconButton sx={{ color: '#006DC3' }} component="span" onClick={() => removeImage(imagen, props.nombres[index])}>
                  <CancelTwoToneIcon />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar
                    key={imagen + index}
                    alt={`Avatar n°${imagen + 1}`}
                    src={imagen}
                    variant="rounded"
                  />
                </ListItemAvatar>
                <ListItemText id={'foto_prestador' + index} primary={props.nombres[index]} />
              </ListItemButton>
            </ListItem>
          );
        }) : <Typography
          sx={{
            paddingLeft: 2,
            fontSize: { sm: 12, },
          }} variant="h6" color="text.secondary">
          {'Vista preliminar'}
        </Typography>}
      </List>
      {/* <Button variant="contained" component="span" sx={{ marginLeft: 2 }} onClick={uploadFiles}>
        Subir
      </Button> */}
    </Box>
  );
}
export function ImageFrontPage(props) {
  const { changeHandler, uploadFileFrontPage, removeImageFrontPage } = props

  const [checked, setChecked] = React.useState([1]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <Box sx={{ paddingBottom: 2 }}>
      <label htmlFor="contained-button-file">
        <Input name="portada" accept="image/*" id="contained-button-file" type="file" onInputCapture={changeHandler} />
        <IconButton color="primary" aria-label="upload picture" component="span">
          <PhotoCamera />
        </IconButton>
        {/* <Button variant="contained" component="span">
          Fotos
        </Button> */}
      </label>
      <List dense sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper' }}>
        {props.vista_previa.length !== 0 ? props.vista_previa.map((imagen, index) => {
          return (
            <ListItem
              key={imagen + index}
              secondaryAction={
                <IconButton sx={{ color: '#006DC3' }} component="span" onClick={() => removeImageFrontPage(imagen, props.nombres[index])}>
                  <CancelTwoToneIcon />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar
                    key={imagen + index}
                    alt={`Avatar n°${imagen + 1}`}
                    src={imagen}
                    variant="rounded"
                  />
                </ListItemAvatar>
                <ListItemText id={'foto_prestador' + index} primary={props.nombres[index]} />
              </ListItemButton>
            </ListItem>
          );
        }) : <Typography
          sx={{
            paddingLeft: 2,
            fontSize: { sm: 12, },
          }} variant="h6" color="text.secondary">
          {'Vista preliminar'}
        </Typography>}
      </List>
      {/* <Button variant="contained" component="span" sx={{ marginLeft: 2 }} onClick={uploadFiles}>
        Subir
      </Button> */}
    </Box>
  );
}

export function ValidateProvider(props) {
  const { changeHandler, handleClickShowPassword, handleMouseDownPassword, onKeyDown } = props
  return (
    <Box>
      <List>
        <ListItem>
          <TextField
            required
            autoFocus
            id="codigo_prestador"
            name="codigo_prestador"
            label="Código de prestador"
            type="number"
            inputProps={{ maxLength: 300, }}
            sx={{ width: '25ch' }}
            value={props.codigo_prestador}
            onChange={changeHandler}
          />
        </ListItem>
        <ListItem>
          <FormControl sx={{ m: 0, width: '25ch' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Clave</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={props.showPassword ? 'text' : 'password'}
              name="password"
              value={props.password}
              onChange={changeHandler}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  onKeyDown()
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {props.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Clave"
            />
          </FormControl>
        </ListItem>
      </List>
    </Box>
  );
}

export function DataPromotion(props) {
  const { changeHandler_CO, changeHandler_CD, changeHandler } = props

  const flatProps = {
    options: props.ciudades.map((option) => option.codigo_ciudad),
  };

  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '100%' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        required
        autoFocus
        hiddenLabel
        id="descripcion"
        name="descripcion"
        label="Descripcion"
        type="text"
        fullWidth
        inputProps={{ maxLength: 200 }}
        value={props.descripcion}
        onChange={changeHandler}
      />
      <TextField
        required
        hiddenLabel
        id="precio"
        name="precio"
        label="Precio"
        type="text"
        fullWidth
        inputProps={{ maxLength: 5 }}
        value={props.precio}
        onChange={changeHandler}
      />
      <Autocomplete
        id="ciudad_origen"
        name="ciudad_origen"
        {...flatProps}
        fullWidth
        value={props.ciudad_origen}
        onChange={changeHandler_CO}
        noOptionsText={'No hay registros coincidentes'}
        renderInput={(params) => <TextField {...params} label="Ciudad orígen" />}
      />
      <Autocomplete
        id="ciudad_destino"
        name="ciudad_destino"
        {...flatProps}
        fullWidth
        value={props.ciudad_destino}
        noOptionsText={'No hay registros coincidentes'}
        renderInput={(params) => <TextField {...params} label="Ciudad destino" />}
        onChange={changeHandler_CD}
      />
    </Box>
  )
}

export default DataBasicProvider;