import React from 'react';
import cx from 'clsx';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import AirportShuttleTwoToneIcon from '@mui/icons-material/AirportShuttleTwoTone';
import VerticalTicketRip from '@mui-treasury/components/rip/verticalTicket';
import { useVerticalRipStyles } from '@mui-treasury/styles/rip/vertical';
import {
  List,
  ListItem,
  ListItemText
} from '@mui/material'

import '../../css/publication.css';

const mainColor = '#003399';
const lightColor = '#ecf2ff';
const borderRadius = 12;

export const PlaneTicketCardDemo = React.memo(function PlaneTicketCard(props) {

  const useStyles = makeStyles(({ palette, breakpoints }) => ({
    card: {
      backgroundColor: 'transparent',
      width: '100%',
      overflow: 'visible',
      background: 'none',
      display: 'flex',
      minWidth: 343,
      minHeight: 180,
      paddingTop: 10,
      filter: 'drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.3))',
      '& $moveLeft, $moveRight': {
        transition: '0.3s',
      },
      '&:hover': {
        '& $moveLeft': {
          transform: 'translateX(-8px)',
        },
        '& $moveRight': {
          transform: 'translateX(8px)',
        },
      },
      [breakpoints.up('sm')]: {
        minWidth: 200,
      },
      '&.css-xg2nla-MuiPaper-root-MuiCard-root': {
        backgroundColor: 'transparent'
      },
      '&.css-mxpiy6': {
        backgroundColor: 'transparent'
      }
      // '& $moveLeft': {
      //   transform: 'rotate(-90deg)'
      // },
      // '& $moveRight': {
      //   transform: 'rotate(-90deg)'
      // },
    },
    left: {
      borderTopLeftRadius: borderRadius,
      borderBottomLeftRadius: borderRadius,
      flexBasis: '33.33%',
      display: 'flex',
      background: '#05d4d6'
    },
    media: {
      margin: 'auto',
      width: 140,
      height: 140,
      borderRadius: '50%',
    },
    right: {
      borderTopRightRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
      flex: 1,
      padding: 12,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      backgroundColor: lightColor,
    },
    label: {
      padding: '0 8px',
    },
    heading: {
      fontSize: 18,
      fontWeight: 'bold',
      margin: 0,
      marginBottom: 4,
    },
    subheader: {
      fontSize: 14,
      margin: 0,
      color: palette.text.secondary,
    },
    path: {
      flex: 1,
      flexBasis: 72,
      padding: '0 4px',
    },
    line: {
      position: 'relative',
      margin: '20px 0 16px',
      borderBottom: '1px dashed rgba(0,0,0,0.38)',
    },
    plane: {
      position: 'absolute',
      display: 'inline-block',
      padding: '0 4px',
      fontSize: 32,
      backgroundColor: lightColor,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%) rotate(0deg)',
    },
    flight: {
      fontSize: 14,
      lineHeight: '24px',
      minWidth: 48,
      padding: '0 8px',
      borderRadius: 40,
      backgroundColor: '#bed0f5',
      color: mainColor,
      display: 'block',
    },
    moveLeft: {},
    moveRight: {},
  }));

  const styles = useStyles();
  const ripStyles = useVerticalRipStyles({
    size: 24,
    rightColor: lightColor,
    tearColor: mainColor,
    leftColor: '#05d4d6',
  });

  return (
    <Card className={styles.card} elevation={0}>
      {/* <div className={cx(styles.left, styles.moveLeft)}>
        <CardMedia
          className={styles.media}
          image={props.imagen_principal}
        />
      </div>
      <VerticalTicketRip
        classes={{
          ...ripStyles,
          left: cx(ripStyles.left, styles.moveLeft),
          right: cx(ripStyles.right, styles.moveRight),
        }}
      /> */}
      <div className={cx(styles.right)}>
        <div className={styles.label}>
          <h3 className={styles.heading}>SALIDA</h3>
          {/* <p className={styles.subheader}>{props.ciudad_origen}</p> */}
          <List dense={true} sx={{ paddingTop: 0, paddingBottom: 0 }}>
            {
              props.ciudad_origen.map((ciudad) => (
                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                  <ListItemText
                    sx={{
                      fontSize: 14,
                      color: 'rgba(0, 0, 0, 0.6)',
                    }}
                    primary={ciudad}
                  />
                </ListItem>
              ))
            }
          </List>
        </div>
        <div className={styles.path}>
          <div className={styles.line}>
            <AirportShuttleTwoToneIcon className={styles.plane} fontSize="large" />
          </div>
          <span className={styles.flight}>{'Ruta del viaje'}</span>
        </div>
        <div className={styles.label}>
          <h3 className={styles.heading}>DESTINO</h3>
          <p className={styles.subheader}>{props.codigo_ciudad}</p>
        </div>
      </div>
    </Card>
  );
});

export default PlaneTicketCardDemo;