
import "react-image-gallery/styles/css/image-gallery.css";

import ImageGallery from 'react-image-gallery';
import FunctionsCommon from "../functions/FunctionsCommon";
import Container from '@mui/material/Container';

import styled from 'styled-components'
import { makeStyles } from '@mui/styles';

import PromotionText from '../components/PromotionText'

import useMediaQuery from '@mui/material/useMediaQuery';

const Gallery = styled(ImageGallery)({
    '& .image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image': {
        maxHeight: '60vh'
    }
});

var React = require('react');

const style = {
    '& .image-gallery': {
        width: '40%',
        height: 'auto',
    },

    '& .image-gallery-slide img': {
        width: '100%',
        height: 'auto',
        maxHeight: '60vh',
        objectFit: 'cover',
        overflow: 'hidden',
        objectPosition: 'center center',
    },

    '& .fullscreen.image-gallery-slide img': {
        maxHeight: '100vh'
    },

    '& .image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image': {
        maxHeight: '60vh'
    }

}

const styleGallery = {
    '& .image-gallery-content.left .image-gallery-slide .image-gallery-image,.image-gallery-content.right .image-gallery-slide .image-gallery-image': {
        maxHeight: '60vh'
    }
}

function myRenderItem(title, index) {
    return <div>
        <span style={{ left: '100%' }} className={`image-gallery-description ${index}`}>{title}</span>
    </div>;
}

const someComponent = () => {
    return <PromotionText nombre_imagen={'PROMO1'} descricion={'hola esta es una image'} />;
};

const _renderVideo = (item) => {
    return (
        <div>
            {
                <div className='video-wrapper'>
                    <a
                        className='close-video'
                    >
                    </a>
                    <iframe
                        width='560'
                        height='315'
                        src={item.embedUrl}
                        frameBorder='0'
                        allowFullScreen
                    >
                    </iframe>
                </div>
            }
        </div>
    );
}

export default function CarouselTab(props) {

    const obtenerImagen = (arreglo) => {
        let data = [];

        arreglo.map((item, index) => (
            data.push({
                original: `${FunctionsCommon.obtenerImagenDesdeItem(props.codigo_prestador, item.nombre_imagen, props.tipo, item.tipo_imagen, 'Prestadores')}`,
                thumbnail: `${FunctionsCommon.obtenerImagenDesdeItem(props.codigo_prestador, item.nombre_imagen, props.tipo, item.tipo_imagen, 'Prestadores')}`,
                description: item.tipo_promo === 't' ? item.descripcion : ''                
            })
        ));

        return data
    }

    const useStyles = makeStyles(() => ({
        gallery: {
            '& .image-gallery-content.left .image-gallery-slide .image-gallery-image,.image-gallery-content.right .image-gallery-slide .image-gallery-image': {
                minHeight: '60vh',
                maxHeight: '60vh'
            },
            '& .image-gallery-description': {
                paddingLeft: '80px !important',
                paddingRight: '80px !important',
                textAlign: 'left',
                paddingTop: '50px',
                fontStyle: 'italic',
                fontSize: '20px',
                lineHeight: 'normal',
                minHeight: '60vh',
                maxHeight: '60vh',
                bottom: 0,
                top: 0,
                right: 0
            }
        },
        galleryM320: {
            '& .image-gallery-content.left .image-gallery-slide .image-gallery-image,.image-gallery-content.right .image-gallery-slide .image-gallery-image': {
                maxHeight: '60vh'
            },
            '& .image-gallery-description': {
                paddingLeft: '40px !important',
                paddingRight: '40px !important',
                textAlign: 'left',
                paddingTop: '0px',
                fontStyle: 'italic',
                fontSize: '10px',
                lineHeight: 'normal',
                minHeight: '60vh',
                maxHeight: '60vh',
                bottom: 0,
                top: 0,
                right: 0
            }
        }
    }));

    const classes = useStyles();

    const m320 = useMediaQuery('(max-width:320px)');
    const m360 = useMediaQuery('(max-width:360px)');
    const m375 = useMediaQuery('(max-width:375px)');
    const m411 = useMediaQuery('(max-width:411px)');
    const m414 = useMediaQuery('(max-width:414px)');
    const m425 = useMediaQuery('(max-width:425px)');

    return (
        <Container className={m320 || m360 || m375 || m411 || m414 || m425 ? classes.galleryM320 : classes.gallery} component="section" sx={{ mt: 2, mb: 4, width: '100%', height: '50%', textAlign: '-webkit-center' }}>
            {<Gallery
                items={obtenerImagen(props.imagenes)}
                showBullets={true}
                thumbnailPosition="left"
                infinite
                showIndex
            />}
        </Container>
    );
}
//ReactDOM.render(<CarouselTab />, document.querySelector('.demo-carousel'));

// <Carousel
//                 showArrows={true}
//                 autoPlay={false}
//                 infiniteLoop
//                 axis="vertical"
//                 height="300px"
//                 animationHandler="fade" swipeable={false}
//                 thumbWidth="100px">
//                 {props.imagenes.map((item) => (
//                     <div style={{ height: '300px' }}>
//                         <img style={{ height: '300px' }} src={FunctionsCommon.obtenerImagenDesdeItem(item.nombre_imagen)} />
//                         <p className="legend">{item.comentario}</p>
//                     </div>
//                     // <ImageListItem
//                     //     key={item.npombre_imagen}
//                     //     sx={{
//                     //         height: 200,
//                     //         width: { xs: '100%', md: 200, sm: 200 },
//                     //         margin: 0,
//                     //         display: 'flex',
//                     //         objectFit: { xs: 'cover' },
//                     //         maxHeight: { xs: 100, md: 200, sm: 200 },
//                     //         maxWidth: { xs: '100%', md: 200, sm: 200 },
//                     //     }}
//                     // >
//                     //     <Img
//                     //         className={classes.imageItem}
//                     //         src={`${FunctionsCommon.obtenerImagenDesdeItem(item.nombre_imagen)}?w=248&fit=crop&auto=format`}
//                     //         srcSet={`${FunctionsCommon.obtenerImagenDesdeItem(item.nombre_imagen)}?w=248&fit=crop&auto=format&dpr=2 2x`}
//                     //         alt={item.comentario}
//                     //     />
//                     // </ImageListItem>
//                 ))}
//             </Carousel>