import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import {
    Box,
    Container,
} from '@mui/material';

import StepperProvider from '../components/StepperProvider'
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import StepperProviderUpdate from './StepperProviderUpdate';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function VerticalTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Container component="section"
            sx={{
                marginTop: 12,
                background: '#eee',
            }}
        >
            <Tabs
                orientation="horizontal"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderColor: 'divider' }}
            >
                <Tab sx={{ marginLeft: 3 }} icon={<AddCircleTwoToneIcon />} label="Crear prestador" {...a11yProps(0)} />
                <Tab icon={<BorderColorTwoToneIcon />} label="Actualizar prestador" {...a11yProps(1)} />

            </Tabs>
            <TabPanel value={value} index={0}>
                <StepperProvider />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <StepperProviderUpdate />
            </TabPanel>

        </Container>
    );
}
