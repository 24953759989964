import * as React from 'react';

import FunctionsCommon from '../functions/FunctionsCommon';
import '../../css/ProvidersByCityAndService.css'

import {
    Alert,
    AlertTitle,
    Container,
    Card,
    Button,
    ButtonBase,
    CardActions,
    Typography,
    SnackbarContent,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import ReadMoreIcon from '@mui/icons-material/ReadMore';

import DeckTwoToneIcon from '@mui/icons-material/DeckTwoTone';
import CommuteTwoToneIcon from '@mui/icons-material/CommuteTwoTone';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import HikingTwoToneIcon from '@mui/icons-material/HikingTwoTone';
import LuggageTwoToneIcon from '@mui/icons-material/LuggageTwoTone';
import DiningTwoToneIcon from '@mui/icons-material/DiningTwoTone';
import HotelTwoToneIcon from '@mui/icons-material/HotelTwoTone';
import VpnLockTwoToneIcon from '@mui/icons-material/VpnLockTwoTone';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';

import { Link, useParams } from "react-router-dom";
import CustomCard from './Provider/CustomCard/CustomCard';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    textTransform: 'none',
    backgroundColor: purple[500],
    '&:hover': {
        backgroundColor: purple[700],
    },
}));

const obtenerIcon = (codigo, contador) => {
    switch (codigo) {
        case 0:
            return <BallotTwoToneIcon />
        case 1:
            return <HotelTwoToneIcon />
        case 2:
            return <DiningTwoToneIcon />
        case 3:
            return <HikingTwoToneIcon />
        case 4:
            return <LuggageTwoToneIcon />
        case 5:
            return <CommuteTwoToneIcon />
        case 6:
            return <DeckTwoToneIcon />
        case 7:
            return <VpnLockTwoToneIcon />
        case 8:
            return <StarTwoToneIcon />
    }
}

export default function CardProviderByCityAndService(props) {

    const params = useParams();

    const [prestadores, setPrestadores] = React.useState(props.prestadores.sort(() => Math.random() - 0.5))

    const Redirigir = (url) => {
        console.log(url);
        window.open(url);
    }

    const useStyles = makeStyles(() => ({
        transicion: {
            transition: '0.2s',
            '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: '11px 10px 13px -6px rgba(0,0,0,0.73)',
                webkitBoxShadow: '11px 10px 13px -6px rgba(0,0,0,0.73)',
                mozBoxShadow: '11px 10px 13px -6px rgba(0,0,0,0.73)'
            },
            alertIcon: {
                '&.css-1ytlwq5-MuiAlert-icon': {
                    padding: '11px 0 !important',
                }
            }
        },
    }));

    const classes = useStyles();

    return (
        <Container sx={{ paddingLeft: '0px !important' }}>
            <Alert
                sx={{
                    fontSize: '23px !important',
                    color: 'white',
                    backgroundColor: 'transparent !important',
                    '& .css-1ytlwq5-MuiAlert-icon': {
                        padding: '11px 0 !important',
                    }
                }}
                iconMapping={{
                    success: obtenerIcon(props.codigo_servicio),
                }}
            >
                {props.titulo}
            </Alert>
            {props.prestadores.sort(() => Math.random() - 0.5).map((prestador, index) => (
                params.tipo_servicio === undefined ?
                    index <= 3 ?
                        < div className="contenedor_tarjetas_prestador_principal" key={prestador.codigo_prestador}>
                            <div className="contenedor_tarjetas_prestador">
                                <CustomCard
                                    imagen={`${FunctionsCommon.obtenerImagenPrincipalJSON(prestador.codigo_prestador, prestador.imagenes)}`}
                                    imagen_principal={`${FunctionsCommon.obtenerImagenPrincipalJSON(prestador.codigo_prestador, prestador.imagenes)}`}
                                    nombre={prestador.nombre}
                                    latestPostLarge={false}
                                    latestPost={true}
                                    url_prestador={`/perfil/${prestador.url}`}
                                />
                                {/* <Card className={classes.transicion} sx={{ maxHeight: 350, borderRadius: '10px' }}>
                                    <ButtonBase sx={{ width: '100%' }} onClick={() => Redirigir(`/perfil/${prestador.url}`)}>
                                        <img
                                            className="contenedor_tarjetas_imagenes_ciudades"
                                            src={`${FunctionsCommon.obtenerImagenPrincipalJSON(prestador.codigo_prestador, prestador.imagenes)}`}
                                        />
                                    </ButtonBase>
                                    <CardActions
                                        disableSpacing
                                        sx={{
                                            background: '#13A20A',
                                            minHeight: '70px',
                                            maxWidth: '250px',
                                            paddingBottom: 0
                                        }}>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {prestador.nombre}
                                        </Typography>
                                    </CardActions>
                                </Card> */}
                            </div>
                        </div> : null
                    :
                    < div className="contenedor_tarjetas_prestador_principal" key={prestador.codigo_prestador}>
                        <div className="contenedor_tarjetas_prestador">
                            <CustomCard
                                imagen={`${FunctionsCommon.obtenerImagenPrincipalJSON(prestador.codigo_prestador, prestador.imagenes)}`}
                                imagen_principal={`${FunctionsCommon.obtenerImagenPrincipalJSON(prestador.codigo_prestador, prestador.imagenes)}`}
                                nombre={prestador.nombre}
                                latestPostLarge={false}
                                latestPost={true}
                                url_prestador={prestador.url}
                            />
                            {/* <Card className={classes.transicion} sx={{ maxHeight: 350, borderRadius: '10px' }}>
                                <ButtonBase sx={{ width: '100%' }} onClick={() => Redirigir(`/perfil/${prestador.url}`)}>
                                    <img
                                        className="contenedor_tarjetas_imagenes_ciudades"
                                        src={`${FunctionsCommon.obtenerImagenPrincipalJSON(prestador.codigo_prestador, prestador.imagenes)}`}
                                    />
                                </ButtonBase>
                                <CardActions
                                    disableSpacing
                                    sx={{
                                        background: '#13A20A',
                                        minHeight: '70px',
                                        maxWidth: '250px',
                                        paddingBottom: 0
                                    }}>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {prestador.nombre}
                                    </Typography>
                                </CardActions>
                            </Card> */}
                        </div>
                    </div>
            ))}
            {
                params.tipo_servicio === undefined ?
                    props.prestadores.length > 3
                        ?
                        <SnackbarContent
                            message=""
                            sx={{
                                background: 'transparent !important',
                                boxShadow: 'none !important'
                            }}
                            action={
                                <ColorButton endIcon={<ReadMoreIcon />} onClick={() => Redirigir(`/${props.url}/${props.tipo_servicio}`)}>
                                    Ver más
                                </ColorButton>
                            } /> : null
                    : null
            }
        </Container>
    );
}