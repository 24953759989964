import * as React from 'react'
import CardActionArea from '@mui/material/CardActionArea';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {
    Grid,
    Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles';
import Color from 'color';

const useGridStyles = makeStyles(({ breakpoints }) => ({
    root: {
        [breakpoints.up('md')]: {
            justifyContent: 'center',
        },
        position: 'fixed',
        zIndex: 1,
        position: 'absolute',
        top: '60%',
        left: '51%',
        transform: 'translate(-50%, -50%)',        
    },
}));

const useStyles = makeStyles(() => ({
    actionArea: {
        borderRadius: 16,
        transition: '0.2s',
        '&:hover': {
            transform: 'scale(1.1)',
        },
    },
    card: ({ color }) => ({
        minWidth: 256,
        borderRadius: 16,
        boxShadow: 'none',
        '&:hover': {
            boxShadow: `0 6px 12px 0 ${Color(color)
                .rotate(-12)
                .darken(0.2)
                .fade(0.5)}`,
        },
    }),
    content: ({ color }) => {
        return {
            backgroundColor: color,
            padding: '1rem 1.5rem 1.5rem',
        };
    },
    title: {
        fontFamily: 'Keania One',
        fontSize: '2rem',
        color: '#fff',
        textTransform: 'uppercase',
    },
    subtitle: {
        fontFamily: 'Montserrat',
        color: '#fff',
        opacity: 0.87,
        marginTop: '2rem',
        fontWeight: 500,
        fontSize: 14,
    },
}));

const CustomCard = ({ classes, image, title, subtitle }) => {
    return (
        <CardActionArea className={classes.actionArea}>
            <Card className={classes.card}>
                <CardMedia component="img" image={image} sx={{ maxHeight: '120px' }} />
                <CardContent className={classes.content}>
                    <Typography className={classes.title} variant={'h2'}>
                        {title}
                    </Typography>
                    <Typography className={classes.subtitle}>{subtitle}</Typography>
                </CardContent>
            </Card>
        </CardActionArea>
    );
};

export const SolidGameCardDemo = React.memo(function SolidGameCard() {
    const gridStyles = useGridStyles();
    const styles = useStyles({ color: '#203f52' });
    const styles2 = useStyles({ color: '#4d137f' });
    const styles3 = useStyles({ color: '#ff9900' });
    const styles4 = useStyles({ color: '#34241e' });
    return (
        <>
            <Grid classes={gridStyles} container spacing={4} wrap={'nowrap'}>
                <Grid item>
                    <CustomCard
                        classes={styles}
                        title={'PRESTADOR 1'}
                        subtitle={'Posadas'}
                        image={
                            'https://steamcdn-a.akamaihd.net/apps/dota2/images/blog/play/dota_heroes.png'
                        }
                    />
                </Grid>
                <Grid item>
                    <CustomCard
                        classes={styles2}
                        title={'PRESTADOR 2'}
                        subtitle={'Traslados en lanchas'}
                        image={
                            'https://progameguides.com/wp-content/uploads/2019/10/fortnite-outfit-scratch.jpg'
                        }
                    />
                </Grid>
                <Grid item>
                    <CustomCard
                        classes={styles3}
                        title={'PRESTADOR 3'}
                        subtitle={'Alquiler de toldos'}
                        image={'https://images5.alphacoders.com/690/thumb-1920-690653.png'}
                    />
                </Grid>
                <Grid item>
                    <CustomCard
                        classes={styles4}
                        title={'PRESTADOR 4'}
                        subtitle={'Restaurant'}
                        image={
                            'https://www.itp.net/public/styles/full_img_sml/public/images/2019/05/27/44485-pubg_base1.jpg?itok=EF911Xan'
                        }
                    />
                </Grid>
            </Grid>
        </>
    );
});
export default SolidGameCardDemo