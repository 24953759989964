import np from "../../assets/images/np.jpg";

import { Component } from 'react';

import ecos from '../../assets/images/ecosistemas.png'
import { breadcrumbsClasses } from "@mui/material";

const playas = [
    {
        id: 'posadas',
        title: 'posadas',
    },
    {
        id: 'restaurant',
        title: 'Restaurant',
    },
    {
        id: 'lanchas',
        title: 'Lanchas',
    },
    {
        id: 'toldos',
        title: 'Toldos',
    },
    {
        id: 'paseos',
        title: 'Paseos',
    },
];
const montanas = [
    {
        id: 'posadas',
        title: 'Posadas',
    },
    {
        id: 'restaurant',
        title: 'Restaurant',
    },
    {
        id: 'paseos',
        title: 'Paseos',
    },
];
const llanos = [
    {
        id: 'posadas',
        title: 'Posadas',
    },
    {
        id: 'paseos',
        title: 'Paseos',
    },
];
const ciudades = [
    {
        id: 'posadas',
        title: 'Posadas',
    },
];

class FunctionsCommon extends Component {

    constructor(props) {
        super(props);

        this.state = {
            busqueda: [],
            ecosistema: "",
            opcioneco: "",
            estatus: "",
            idActual: -1,
            idAnterior: -1,
            opciones: [],
            activo: false
        };
    }

    componentWillMount() {
        this.clickBuscar = this.clickBuscar.bind(this);
    }

    obtenerImagenPrincipalJSON = (codigo, prestador) => {

        let arreglo = [];
        for (let i in prestador) {
            arreglo.push(prestador[i].tipo_imagen);
        }

        let index = arreglo.indexOf('P');
        if (parseInt(index) === -1) {
            return `${np}`;
        } else {
            //console.log(`${process.env.REACT_APP_URL_HOST}/Imagenes/Prestadores/${codigo}/${prestador[index].nombre_imagen}`);
            return `${this.obtenerURL_Imagenes()}/${this.obtenerCapetaPublica()}Imagenes/Prestadores/${codigo}/General/${prestador[index].nombre_imagen}`;
        }
    }

    obtenerImagenDesdeItem = (codigo, img, tipo, subtipo, ubicacion) => {
        //console.log(codigo, img, tipo, subtipo, ubicacion);      
        //promo.codigo_prestador, nombre_imagen, 'publicaciones', tipo_imagen, 'Prestadores'  
        try {
            switch (ubicacion) {
                case 'Prestadores':

                    if (tipo === "fotos") {
                        if (img === '') {
                            return `${np}`;
                        } else {
                            //console.log(`${this.obtenerURL_Imagenes()}/${this.obtenerCapetaPublica()}Imagenes/${ubicacion}/${codigo}/General/${img}`);
                            return `${this.obtenerURL_Imagenes()}/${this.obtenerCapetaPublica()}Imagenes/${ubicacion}/${codigo}/General/${img}`;
                        }
                    } else {
        
                        if (subtipo.toString().trim() === 'I') {
                            if (img === '') {
                                return `${np}`;
                            } else {                                   
                                return `${this.obtenerURL_Imagenes()}/${this.obtenerCapetaPublica()}Imagenes/${ubicacion}/${codigo}/Promociones/${img}`;
                            }
                        } else {
                            return `${ecos}`;
                        }
        
                    }
                    
                case 'Ciudades':                    
                    return `${this.obtenerURL_Imagenes()}/${this.obtenerCapetaPublica()}Imagenes/${ubicacion}/${img}`;

            }            
        } catch (error) {
            return `${np}`;
        }
    }

    clickBuscar = (filtro, ecosistema, opcioneco) => {

        let data = [];
        let prestadores = [];
        let busqueda = [];

        const result = [...new Set(filtro.map(a => JSON.stringify(a)))].map(a => JSON.parse(a))

        //console.log("Sin repetidos es:", result);

        for (let i in result) {
            if (result[i].id !== undefined) {
                prestadores.push({
                    'id': result[i].id,
                })
            } else {
                if (result[i] !== '') {
                    busqueda.push(
                        result[i]
                    )
                }
            }
        }

        // console.log('prestadores', prestadores);
        // console.log('busqueda', busqueda);

        data.push(
            {
                'ecosistema': ecosistema,
                'opcioneco': opcioneco,
                'busqueda': busqueda,
                'prestadores': prestadores
            }
        );


        return data;

    }

    ecoSistemaSeleccionado = (valor) => {
        this.setState({
            ecosistema: valor
        }, () => { });
    }

    validarDestacados = (array) => {
        try {
            if (array.length !== 0) {
                for (let i = 0; i < array.length; i++) {
                    if (array[i].tipo_servicios.length !== 0) {
                        for (let j = 0; j < array[i].tipo_servicios.length; j++) {
                            if (array[i].tipo_servicios[j].codigo_servicio === 8) {
                                return true;
                            }
                        }
                    } else {
                        return false;
                    }
                }
            } else {
                return false
            }

            return false;

        } catch (error) {
            return false;
        }
    }

    obtenerURL_Principal() { //PARA OBTENER URL EN ENTORNOS PRODUCTIVOS
        let url_principal = window.location.host;
        //console.log(url_principal);
        let partes = url_principal.split('.');
        switch (parseInt(partes.length)) {
            case 1: return 'http://' + url_principal; //ENTORNO DE DESARROLLO CON PUERTO 4000
            case 2: return 'https://' + partes[0] + '.' + partes[1]; //DOMINIO
            case 3:
                if (partes[0] !== 'www') {
                    //console.log(url_principal.replace(/app/g, 'api'));
                    let url_api = partes[0].replace(/app/g, 'api');
                    return 'https://' + url_api + '.' + partes[1] + '.' + partes[2];  //SI API ESTA EN SUBDOMINIO
                } else {
                    return 'https://' + partes[1] + '.' + partes[2]; //SUBDOMINIO
                }
        }
    }

    obtenerURL() { //PARA OBTENER URL EN ENTORNOS PRODUCTIVOS
        let url_principal = window.location.host;
        if (url_principal.toString().includes('localhost')) {
            return process.env.REACT_APP_URL_HOST;
        } else {
            return window.location.host;
        }
    }

    obtenerCapetaPublica() {
        if (window.location.host.toString().includes('localhost')) {
            return ''
        } else {
            return 'api-turismo/public/'
        }
    }

    obtenerURL_Imagenes() { //PARA OBTENER URL EN ENTORNOS PRODUCTIVOS
        let url_principal = window.location.host;
        //console.log(url_principal);
        if (url_principal.toString().includes('localhost')) {
            return process.env.REACT_APP_URL_HOST_DEV
        } else {
            return process.env.REACT_APP_URL_API
        }
    }

}

export default new FunctionsCommon();