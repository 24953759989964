import * as React from 'react';
import {
    Avatar,
    ButtonBase,
    Card,
    CardContent,
    CardActions,
    IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import CardHeader from '@mui/material/CardHeader';
import { makeStyles } from '@mui/styles';
import FunctionsCommon from '../functions/FunctionsCommon';
import '../../css/style.css'

import useMediaQuery from '@mui/material/useMediaQuery';

import SocialMedia from './SocialMedia';

import { Link, useParams } from "react-router-dom";
export default function CardProvider(props) {
    
    const [expanded, setExpanded] = React.useState(false);

    const params = useParams();

    const Redirigir = (codigo_prestador, codigo_promocion, nombre_imagen, url) => {
        //window.open(url);
        let data = [];
        data.push({
            codigo_prestador: codigo_prestador,
            codigo_promocion: codigo_promocion,
            nombre_imagen: nombre_imagen
        })

        data = JSON.stringify(data);
        // const navigate = useNavigate();
        // navigate.push(`/full-day-viajes-excursiones/${data}`);
        //console.log(codigo_prestador, codigo_promocion, url);
    }

    const useStyles = makeStyles(() => ({
        transicion: {
            transition: '0.1s',
            '&:hover': {
                transform: 'scale(1.02)',
            },
        },
        floatingWhatsApp_450: {
            '& .styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE': {
                top: '30px',
                left: '-225px',
            }
        },
        floatingWhatsApp: {
            '& .styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE': {
                top: '30px',
                right: '30px',
            }
        },
        cardProvider: {
            '&.css-bhp9pd-MuiPaper-root-MuiCard-root': {
                overflow: 'initial !important',
            }
        }

    }));

    const classes = useStyles();
    const m450 = useMediaQuery('(max-width:450px)');

    return (
        <Card className={classes.cardProvider}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: red[500], width: 56, height: 56 }} aria-label="recipe" src={props.imagen} />
                }
                action={<SocialMedia
                    nombre={props.nombre}
                    imagen={props.imagen}
                    comunicaciones={props.comunicaciones}
                    resolucion_m450={m450}
                    floatingWhatsApp_450={classes.floatingWhatsApp_450}
                    floatingWhatsApp={classes.floatingWhatsApp}
                />
                }
                title={props.nombre}
            />
            <CardContent>
                {props.publicaciones.map((promo) => (
                    <div className="contenedor_tarjetas_promociones">
                        <Card elevation={3} className={classes.transicion} sx={{ maxHeight: 300, }}>
                            <ButtonBase sx={{ width: '100%' }}>
                                <Link to={{
                                    pathname: `/full-day-viajes-excursiones-en-venezuela/${params.url}/${promo.url}`
                                }}>
                                    <img
                                        className="contenedor_tarjetas_imagenes_promociones"
                                        src={`${FunctionsCommon.obtenerImagenDesdeItem(props.codigo_prestador, promo.nombre_imagen, 'promociones', promo.tipo_imagen, 'Prestadores')}`}
                                    />
                                </Link>
                            </ButtonBase>
                            <CardActions disableSpacing sx={{ background: '#13A20A' }}>
                                <IconButton aria-label="agregar a favoritos">
                                    <FavoriteIcon />
                                </IconButton>
                                <IconButton aria-label="compartir">
                                    <ShareIcon />
                                </IconButton>
                            </CardActions>
                        </Card>
                    </div>
                ))}
            </CardContent>
        </Card>
    );
}