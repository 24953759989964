import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { Container, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Divider } from '@mui/material';
import logo_letras from '../../assets/images/logo_circular2.png';
import ContainerEco from '../components/ContainerEco'

import AppBar from '@mui/material/AppBar';
import { DivUI as Div } from '../components/BaseComponents'

import useMediaQuery from '@mui/material/useMediaQuery';

import Search from '../components/Search'

const Image = styled.img`
    height: 70px;
    width: 70px;
    cursor: pointer; 
    z-index: 1;
`
const DividerUI = styled(Divider)`
width: 100%;
`
export const InputUI = styled.input`
    borderRadius: 15px;
    border: 1px;
    height: 50px;
    width: 95%;
    type: search;
    arial-label: Búsqueda;
    margin: 10px;
`

export const ContainerUI = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    justify-content: space-between;
    position: relative;
`
export const Container_Inline = styled.div`
    display: inline;
`

export const H1 = styled.h1`
    width: 100%;
`
export const DivUI = styled.div`
    width: 100%;        
    max-height: 125px;
`

export const DivUIPrincipal = styled.div`
    width: 100%;
    background: transparent;
    padding-bottom: 1px;
`

const irAlInicio = () => () => {
    window.location.replace("/");
};

export function Header(props) {

    const m320 = useMediaQuery('(max-width:320px)');
    const m360 = useMediaQuery('(max-width:360px)');
    const m375 = useMediaQuery('(max-width:375px)');
    const m411 = useMediaQuery('(max-width:411px)');
    const m414 = useMediaQuery('(max-width:414px)');
    const m425 = useMediaQuery('(max-width:425px)');
    const m768 = useMediaQuery('(max-width:768px)');
    const m1024 = useMediaQuery('(max-width:1024px)');
    const m1440 = useMediaQuery('(max-width:1440px)');
    const m1600 = useMediaQuery('(max-width:1600px)');
    const m1800 = useMediaQuery('(max-width:1800px)');

    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    const obtenerBackground = () => {
        if (offset === 0) {
            return 'transparent'
        } else {
            return 'rgba(0, 212, 255, 0.7) !important'
        }
    }

    const obtenerBoxShadow = () => {
        if (offset === 0) {
            return 'unset'
        } else {
            return '-moz-initial'
        }
    }

    const clickBuscar = () => {

    }

    const buscarPrestador = () => {
        
    }

    return (
        <AppBar sx={{ backgroundColor: obtenerBackground(), backdropFilter: 'blur(1px)', boxShadow: obtenerBoxShadow() }} >
            <Div>
                <Container>
                    <ContainerUI>
                        <Image
                            onClick={irAlInicio()}
                            src={logo_letras}
                        />
                        <Typography
                            sx={{
                                fontSize: { sm: 28, },
                                fontStyle: 'italic',
                                fontWeight: 'bold',
                                color: 'azure',
                                paddingLeft: 1
                            }}
                            variant="body1"
                            color="text.secondary"
                        >
                            ...turismo a la carta
                        </Typography>
                    </ContainerUI>
                    <DividerUI />
                </Container>
                {/* <Search
                    localidades={[]}
                    clickBuscar={clickBuscar}
                    buscarPrestador={buscarPrestador}
                    ecosistema={0}
                    opcioneco={ 0}
                    loading={false}
                    visibility={
                        window.location.toString().includes('/preregistro') || 
                        window.location.toString().includes('/registrar/') || 
                        window.location.toString().includes('localhost') || 
                        window.location.toString() === process.env.REACT_APP_URL_HOST + '/' ? 'collapse' : 'visible'}
                    height={
                        window.location.toString().includes('/preregistro') || 
                        window.location.toString().includes('/registrar/') ||
                        window.location.toString().includes('localhost') || 
                        window.location.toString() === process.env.REACT_APP_URL_HOST + '/' ? 0 : '100%'}
                /> */}
            </Div>
        </AppBar>
    )
}

export default Header;
