import * as React from 'react';
import { useState, useEffect } from 'react';
import withRoot from './modules/withRoot/withRoot';

import Footer from './modules/components/Footer'

import FloatingWhatsApp from 'react-floating-whatsapp-body';
import Data from './servicio/Data';

import { DivUI, H1, HeaderUI } from './modules/components/BaseComponents'

import ChatBotReact from './modules/components/ChatBotReact';

import { DivUIPrincipal } from './modules/components/BaseComponents'

import AppBar from '@mui/material/AppBar';

import LayoutCenter from './modules/components/LayoutCenter'

import Backdrop from '@mui/material/Backdrop';
import { Box, Container } from '@mui/material';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';

import { Alert as MuiAlert, Snackbar } from '@mui/material'

import InsertComments from './modules/components/InsertComment';
import CommentsService from './servicio/CommentsService';

import { HashRouter, Route, Routes, Switch } from 'react-router-dom';

import SkeletonCard from "./modules/components/SkeletonCard"

import FunctionsCommon from './modules/functions/FunctionsCommon';

const variants = ['h1', 'h3', 'body1', 'caption'];

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function HomeLayout() {

    const [openLoading, setOpenLoading] = useState(true);
    const [codigo_prestador, setCodigo_Prestador] = useState(0);

    const [openInsertComment, setOpenInsertComment] = useState(false);
    const [open, setOpen] = React.useState(false)
    const [message, setMessage] = React.useState('')
    const [colorMessage, setColorMessage] = React.useState('success')
    const [vertical, setVertical] = React.useState('bottom');
    const [horizontal, setHorizontal] = React.useState('center');

    const [contador_todos, setContador_Todos] = useState([]);
    const [contador_hoteles, setContador_Hoteles] = useState([]);
    const [contador_restaurantes, setContador_Restaurantes] = useState([]);
    const [contador_paseos, setContador_Paseos] = useState([]);
    const [contador_toldos, setContador_Toldos] = useState([]);
    const [contador_embarcaciones, setContador_Embarcaciones] = useState([]);
    const [contador_operadores, setContador_Operadores] = useState([]);
    const [contador_seguros, setContador_Seguros] = useState([]);
    const [contador_destacados, setContador_Destacados] = useState([]);
    const [posicion_por_defecto, setPosicion_Por_Defecto] = useState(0)
    const [prestadores, setPrestadores] = useState([]);
    const [prestadores_busqueda, setPrestadoresBusqueda] = useState([]);
    const [premium, setPremium] = useState([]);
    const [localidades, setLocalidades] = useState([]);
    const [opciones, setOpciones] = useState([]);
    const [chatbotsteps, setChatBotSteps] = useState([]);

    const [loading, setLoading] = React.useState(false);

    const [paises, setPaises] = React.useState([])
    const [estados, setEstados] = React.useState([])

    const [servicios, setServicios] = React.useState([])
    const [codigo_servicio, setCodigoServicio] = React.useState(8);
    const [busqueda, setBusqueda] = React.useState([]);
    const [checkedCiudades, setCheckedCiudades] = React.useState('');
    const [resultadosBusqueda, setResultadosBusqueda] = React.useState([]);

    const [checkedPaises, setCheckedPaises] = React.useState('ven');
    const [ciudades, setCiudades] = React.useState([]);
    const [expanded, setExpanded] = React.useState(false);

    const [drawer, setDrawer] = React.useState(false);

    const [page, setPage] = React.useState(1);

    const clickBuscar = (filtro, ecosistema, opcioneco) => {
        
        setOpenLoading(true);
        let datos = busqueda;
        let nuevo_array = [];


        nuevo_array.push({
            codigo_pais: "",
            codigo_estado: "",
            codigo_ciudad: "",
            busqueda: filtro,
            codigo_servicio: 0
        })

        setBusqueda(datos);
        setBusqueda((state) => {
            return state;
        });

        Data
            .consultaAmpliada(nuevo_array[0])
            .then((response) => {

                if (FunctionsCommon.validarDestacados(response.data)) {
                    setCodigoServicio(8);
                } else {
                    setCodigoServicio(0);
                }

                setPrestadores(response.data);
                setOpenLoading(false);
                window.scrollTo({ top: 0, behavior: 'smooth' });

            })

    }

    const buscarPrestador = (solicitud) => {
        setLoading(true);
        setOpenLoading(true);
        Data
            .prestadorCodigo(solicitud)
            .then((response) => {
                setPrestadores(response.data);
                setLoading(false);
                setOpenLoading(false);
            },
                error => {
                }
            );
    }
    useEffect(() => {

        setOpenLoading(true);
        Data
            .prestadores()
            .then((response) => {

                setPrestadores(response.data);
                setOpenLoading(false);
            },
                error => {
                }
            );
    }, [])
    useEffect(() => {

        ChargeTabSelect()
        CalcularCantidadPrestadores()        

    }, [prestadores, page]);

    const handleChangeTab = (event, newCodigoServicio) => {

        var result = []
        setCodigoServicio(newCodigoServicio);
        prestadores.forEach(element => {
            for (let i = 0; i < element.tipo_servicios.length; i++) {
                if (element.tipo_servicios[i].codigo_servicio === newCodigoServicio) {
                    result.push(element)
                }
            }
        });
        //setPage(0);
        setPrestadoresBusqueda(result)

    };
    function ChargeTabSelect() {
        var result = []

        prestadores.forEach(element => {
            for (let i = 0; i < element.tipo_servicios.length; i++) {
                if (element.tipo_servicios[i].codigo_servicio === codigo_servicio) {
                    result.push(element)
                }
            }



        });

        setPage(0);

        setPrestadoresBusqueda(result)

    };
    function CalcularCantidadPrestadores() {
        var result = []
        var ch = 0, cr = 0, cp = 0, ct = 0, ce = 0, co = 0, cs = 0, cd = 0
        prestadores.forEach(prestador => {
            prestador.tipo_servicios.forEach(servicio => {
                switch (servicio.codigo_servicio) {

                    case 1:
                        ch += 1
                        break;
                    case 2:
                        cr += 1
                        break;
                    case 3:
                        cp += 1
                        break;
                    case 4:
                        co += 1
                        break;
                    case 5:
                        ce += 1
                        break;
                    case 6:
                        ct += 1
                        break;
                    case 7:
                        cs += 1
                        break;
                    case 8:
                        cd += 1
                        break;
                    default:
                        break;
                }
            });

        });

        setContador_Todos(prestadores.length)
        setContador_Hoteles(ch)
        setContador_Restaurantes(cr)
        setContador_Paseos(cp)
        setContador_Toldos(ct)
        setContador_Embarcaciones(ce)
        setContador_Operadores(co)
        setContador_Seguros(cs)

        cd !== 0 ? setPosicion_Por_Defecto(0) : setPosicion_Por_Defecto(1)

        setContador_Destacados(cd)
    };
    const handleChangeEstados = (panel) => (event, isExpanded) => {        
        if (isExpanded) {
            setOpenLoading(true);
            let datos = [];

            if (busqueda.codigo_pais === undefined) {
                datos.push({
                    codigo_pais: checkedPaises,
                })
            } else {
                let index = datos.indexOf(checkedPaises);
                datos.splice(index, 1);
                datos.push({
                    codigo_pais: checkedPaises
                })
            }

            if (busqueda.codigo_estado === undefined) {
                datos.push({
                    codigo_estado: panel
                })
            } else {
                let index = busqueda.indexOf(panel);
                datos.splice(index, 1);
                datos.push({
                    codigo_estado: panel
                })
            }

            if (busqueda.busqueda === undefined) {
                datos.push({
                    busqueda: ''
                })
            }
            
            setBusqueda(datos);

            Data
                .ciudadesPorCodigoEstado(panel)
                .then((response) => {
                    setOpenLoading(false);
                    let data = response.data;
                    setCiudades(data);
                    setCiudades((state) => {
                        return state;
                    });
                    //setCiudades(response.data);
                })
        }
        
        setExpanded(isExpanded ? panel : false);
    };

    const handleToggleCiudades = (value) => {

        //console.log(value);

        let datos = busqueda;

        if (busqueda.codigo_ciudad === undefined) {
            datos.push({
                codigo_ciudad: value
            })
        } else {
            let index = busqueda.indexOf(value);
            datos.splice(index, 1);
            datos.push({
                codigo_ciudad: value
            })
        }

        let nuevo_array = [];
        let codigo_pais = "";
        let codigo_estado = "";
        let codigo_ciudad = "";
        let busq = "";

        for (let i = 0; i < datos.length; i++) {

            if (datos[i].codigo_pais !== undefined) {
                codigo_pais = datos[i].codigo_pais;
            }

            if (datos[i].codigo_estado !== undefined) {
                codigo_estado = datos[i].codigo_estado;
            }

            if (datos[i].codigo_ciudad !== undefined) {
                codigo_ciudad = datos[i].codigo_ciudad;
            }

            if (datos[i].busqueda !== undefined) {
                busq = datos[i].busqueda;
            }

        }

        nuevo_array.push({
            codigo_pais: codigo_pais,
            codigo_estado: codigo_estado,
            codigo_ciudad: codigo_ciudad,
            busqueda: busq,
            codigo_servicio: 0
        })

        setBusqueda(datos);
        setBusqueda((state) => {
            return state;
        });

        setCheckedCiudades(value);

        setDrawer(false);

        console.log(nuevo_array[0]);

        setOpenLoading(true);
        Data
            .consultaAmpliada(nuevo_array[0])
            .then((response) => {

                if (FunctionsCommon.validarDestacados(response.data)) {
                    setCodigoServicio(8);
                } else {
                    setCodigoServicio(0);
                }

                setPrestadores(response.data);
                setOpenLoading(false);
                window.scrollTo({ top: 0, behavior: 'smooth' });

            })

    };

    const handleTogglePaises = (value) => () => {

        setCheckedPaises(value);
    };

    //MODAL COMENTARIOS
    const clickOpenInsertComment = (codigo) => {
        setCodigo_Prestador(codigo);
        setOpenInsertComment(true);
    }

    const clickCloseInsertComment = () => {
        setOpenInsertComment(false);
    }

    const clickInsertComment = (codigo_usuario, puntuacion, comentario) => {
        for (let i in prestadores_busqueda) {
            if (prestadores_busqueda[i].codigo_prestador === codigo_prestador) {
                prestadores_busqueda[i].comentario.push({
                    codigo_usuario: codigo_usuario,
                    comentario: comentario,
                    puntuacion: puntuacion,
                })
                break;
            }
        }
        setPrestadores(prestadores_busqueda);
        setPrestadores((state) => {
            return state;
        });
    }

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    //MODAL COMENTARIOS

    const toggleDrawer = (open) => (event) => {
        console.log(open);
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setDrawer(open)
    };

    function FacebookCircularProgress(props) {
        return (
            <Box sx={{ position: 'relative' }}>
                <CircularProgress
                    variant="determinate"
                    sx={{
                        color: (theme) =>
                            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                    }}
                    size={80}
                    thickness={4}
                    {...props}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                        animationDuration: '550ms',
                        position: 'absolute',
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                        },
                    }}
                    size={80}
                    thickness={4}
                    {...props}
                />
            </Box>
        );
    }


    return (
        <React.Fragment>
            <Backdrop
                sx={{ zIndex: 1000 }}
                open={openLoading}
            >
                <FacebookCircularProgress />
            </Backdrop>
            <InsertComments
                openInsertComment={openInsertComment}
                codigo_prestador={codigo_prestador}
                clickCloseInsertComment={clickCloseInsertComment}
                clickInsertComment={clickInsertComment}
            />
            <DivUIPrincipal>

                <AppBar sx={{ backgroundColor: 'rgba(0, 212, 255, 0.7) !important', backdropFilter: 'blur(10px)', }} >
                    <DivUI>
                        <HeaderUI
                            localidades={localidades}
                            clickBuscar={clickBuscar}
                            buscarPrestador={buscarPrestador}
                            loading={loading}
                            toggleDrawer={toggleDrawer}
                        />
                    </DivUI>
                </AppBar>

                <LayoutCenter
                    servicios={servicios}
                    handleChangeTab={handleChangeTab}
                    prestadores={prestadores_busqueda}
                    codigo_servicio={codigo_servicio}
                    handleTogglePaises={handleTogglePaises}
                    handleChangeEstados={handleChangeEstados}
                    handleToggleCiudades={handleToggleCiudades}
                    checkedCiudades={checkedCiudades}
                    busqueda={busqueda}
                    checkedPaises={checkedPaises}
                    ciudades={ciudades}
                    expanded={expanded}
                    contador_todos={contador_todos}
                    contador_hoteles={contador_hoteles}
                    contador_restaurantes={contador_restaurantes}
                    contador_paseos={contador_paseos}
                    contador_toldos={contador_toldos}
                    contador_embarcaciones={contador_embarcaciones}
                    contador_operadores={contador_operadores}
                    contador_seguros={contador_seguros}
                    contador_destacados={contador_destacados}      
                    page={page}              
                    openInsertComment={openInsertComment}
                    clickOpenInsertComment={clickOpenInsertComment}
                    clickCloseInsertComment={clickCloseInsertComment}
                    drawer={drawer}
                    toggleDrawer={toggleDrawer}
                />
                <FloatingWhatsApp
                    styles={{
                        width: 35,
                        height: 35,
                        fontSize: 12,
                        boxShadow: `0 3px 6px 0 `,
                    }}
                    allowClickAway
                    allowEsc
                    darkMode
                    phoneNumber="584243044132"
                    accountName="Turismo 123"
                    chatMessage={"Hola, gusto en saludarte" + '\n' + "En que podemos ayudarte?"}
                    placeholder="Escribe un mensaje"
                    statusMessage="Típicamente responde entre 6pm y 7pm"
                />
                {/* <ChatBotReact /> */}
                {/* <AdvancedSearch
          prestadores={premium}
          paises={paises}
          estados={estados}
        /> */}

                
            </DivUIPrincipal>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleCloseSnackBar} variant="filled" anchorOrigin={{ vertical, horizontal }}>
                <Alert onClose={handleCloseSnackBar} severity={colorMessage} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default HomeLayout;
