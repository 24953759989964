import * as React from 'react';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
    Backdrop,
    CircularProgress,
    circularProgressClasses,
} from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton';

import { styled } from '@mui/material/styles';
import { Container, Alert as MuiAlert, Snackbar } from '@mui/material';
import TextField from '@mui/material/TextField';
import { SocialMediaProvider, TypeProvider, ServicesProvider } from './RegisterProvider';
import DataBasicProvider from './RegisterProvider';
import ProviderService from '../../servicio/ProviderService';
import { ImagesProvider, ImageFrontPage, ValidateProvider, DataPromotion } from './RegisterProvider';
import UploadImagesService from '../../servicio/UploadImagesService';
import { useEffect } from 'react';

import Recommended from '../components/Recommended';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress'
import ImportContactsTwoToneIcon from '@mui/icons-material/ImportContactsTwoTone';
import Data from '../../servicio/Data';

const Img = styled('img')({
    margin: 0,
    height: '-webkit-fill-available',
    width: '100%',
    objectFit: 'cover',
    display: 'flex',
});

const steps = [
    {
        id: 1,
        label: 'Validación de identidad',
        description: `Validación de identidad`,
    },
    {
        id: 2,
        label: 'Datos registrados',
        description: `Datos registrados`,
    },
    {
        id: 3,
        label: 'Datos de la promoción',
        description: `Datos de la promoción`,
    },
    {
        id: 4,
        label: 'Fotografía',
        description: `Fotografía`,
    },
];

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

let contador_imagenes = 0

const styleButtonNext = (index) => {
    switch (index) {
        case 0:
            return {
                mt: 1,
                mr: 0,
                visibility: 'hidden',
                width: 0,
                minWidth: 0,
                padding: 0,
                paddingRight: 0
            }
        case 1:
            return {
                mt: 1,
                mr: 1,
            }
        case 2:
            return {
                mt: 1,
                mr: 1,
            }
    }
    // if (validado) {
    //     return {
    //         mt: 1,
    //         mr: 1,
    //     }
    // } else {
    //     return {
    //         mt: 1,
    //         mr: 0,
    //         visibility: 'hidden',
    //         width: 0,
    //         minWidth: 0,
    //         padding: 0,
    //         paddingRight: 0
    //     }
    // }
}

const styleButtonBack = (index) => {

    switch (index) {
        case 0:
            return {
                mt: 1,
                mr: 1,
            }
        case 1:
            return {
                mt: 1,
                mr: 0,
                visibility: 'hidden',
                width: 0,
                minWidth: 0,
                padding: 0,
                paddingRight: 0
            }
        case 2:
            return {
                mt: 1,
                mr: 0,
                visibility: 'hidden',
                width: 0,
                minWidth: 0,
                padding: 0,
                paddingRight: 0
            }
        case 3:
            return {
                mt: 1,
                mr: 0,
                visibility: 'hidden',
                width: 0,
                minWidth: 0,
                padding: 0,
                paddingRight: 0
            }
    }
}

export default function StepperProviderPromotion() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [prestador, setPrestador] = React.useState([]);
    const [nombre, setNombre] = React.useState('');
    const [direccion, setDireccion] = React.useState('');
    const [ciudad, setCiudad] = React.useState('');
    const [municipio, setMunicipio] = React.useState('');
    const [estado, setEstado] = React.useState('');
    const [pais, setPais] = React.useState('');

    const [imagenes, setImagenes] = React.useState([]);
    const [vista_previa, setVistaPrevia] = React.useState([]);
    const [nombres, setNombres] = React.useState([]);

    const [portada, setPortada] = React.useState([]);
    const [vista_previa_portada, setVistaPreviaPortada] = React.useState([]);
    const [nombre_portada, setNombrePortada] = React.useState([]);

    const [open, setOpen] = React.useState(false)
    const [vertical, setVertical] = React.useState('bottom');
    const [horizontal, setHorizontal] = React.useState('center');
    const [colorMessage, setColorMessage] = React.useState('success');
    const [message, setMessage] = React.useState('')

    const [openLoading, setOpenLoading] = React.useState(false);

    const [ciudades, setCiudades] = React.useState([]);
    const [ciudad_origen, setCiudadad_Origen] = React.useState('');
    const [ciudad_destino, setCiudadad_Destino] = React.useState('');
    const [descripcion, setDescripcion] = React.useState('');
    const [precio, setPrecio] = React.useState(0);

    const [tipoprestador, setTipoPrestador] = React.useState({
        todos: true,
        hotel: false,
        restaurant: false,
        paseos: false,
        operador: false,
        buses: false,
        toldos: false,
        seguros: false
    });

    const [porcentaje, setPorcentaje] = React.useState(0);

    const [codigo_prestador, setCodigoPrestador] = React.useState(0);

    const [showPassword, setShowPassword] = React.useState(false);
    const [password, setPassword] = React.useState('');

    const changeHandler = (event) => {
        event.preventDefault();
        //console.log(event.target.name);
        switch (event.target.name) {
            case 'codigo_prestador':
                setCodigoPrestador(event.target.value);
                break;
            case 'password':
                setPassword(event.target.value);
                break;
            case 'nombre':
                setNombre(event.target.value);
                break;
            case 'direccion':
                setDireccion(event.target.value);
                break;
            case 'ciudad':
                setCiudad(event.target.value);
                break;
            case 'municipio':
                setMunicipio(event.target.value);
                break;
            case 'estado':
                setEstado(event.target.value);
                break;
            case 'pais':
                setPais(event.target.value);
                break;

            case 'imagenes':
                //console.log(event.target.files.length);
                console.log(event.target.files);
                if (event.target.files) {
                    //console.log('event.target.files.length', event.target.files.length);
                    if (event.target.files.length > 11) {
                        mostrarNotificacion('error', 'Solo es posible seleccionar máximo 12 imagenes');
                        return
                    }

                    contador_imagenes += event.target.files.length;
                    console.log(contador_imagenes);
                    if (contador_imagenes > 11) {
                        contador_imagenes = contador_imagenes - event.target.files.length;
                        mostrarNotificacion('error', 'Ha excedido el número de imagenes(12)');
                        return
                    }

                    setImagenes(imagenes => [...imagenes, event.target.files])

                    /* Get files in array form */
                    //console.log(event.target.files);
                    const files = Array.from(event.target.files);

                    /* Map each file to a promise that resolves to an array of image URI's */
                    const archivos = [];

                    Promise.all(files.map(file => {
                        archivos.push(file.name);
                        return (new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.addEventListener('load', (ev) => {
                                resolve(ev.target.result);
                            });
                            reader.addEventListener('error', reject);
                            reader.readAsDataURL(file);
                        }));
                    }))
                        .then(images => {

                            /* Once all promises are resolved, update state with image URI array */
                            var joined_1 = vista_previa.concat(images);
                            var joined_2 = nombres.concat(archivos);

                            setVistaPrevia(joined_1)
                            setNombres(joined_2)


                        }, error => {
                            console.error(error);
                        });
                }
                break;
            case 'descripcion':
                setDescripcion(event.target.value);
                break;
            case 'precio':
                setPrecio(event.target.value);
                break;
        }

    }

    const changeHandler_CO = (event, value) => {
        setCiudadad_Origen(value);
    }

    const changeHandler_CD = (event, value) => {
        setCiudadad_Destino(value);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const uploadFiles = (id) => {

        let arreglo_imagenes = [];
        for (let i = 0; i < imagenes.length; i++) {
            for (let j = 0; j < imagenes[i].length; j++) {
                arreglo_imagenes.push(imagenes[i][j])
            }
        }

        let datos_promo = [];
        datos_promo.push({
            codigo_prestador: id,
            nombre: nombre,
            ubicacion: 'Promociones',
            tipo_imagen: 'I',
            descripcion: descripcion,
            ciudad_origen: ciudad_origen,
            ciudad_destino: ciudad_destino,
            precio: precio
        })

        if (arreglo_imagenes.length === 1) {

            const config = {
                headers: { "content-type": `${arreglo_imagenes[0].type}` },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    setPorcentaje(Math.floor((loaded * 100) / total));
                    //console.log(`${loaded}kb de ${total} | ${percent}%`);
                }
            };

            const formData = new FormData();

            formData.append('image', arreglo_imagenes[0])

            axios.post(`${UploadImagesService.obtenerURL_API()}` + `/api/prestadores_imagenes/simple/${JSON.stringify(datos_promo[0])}`, formData, config)
                .then((response) => {
                    //console.log(response);
                    if (response.status === 200) {
                        mostrarNotificacion('success', 'Información registrada con exito')
                        setImagenes([]);
                        setNombres([]);
                        setVistaPrevia([]);
                        limpiar();
                    }

                }, error => {
                    mostrarNotificacion('error', 'Error registrando información: ' + error.toString())
                    console.error(error);
                });

        } else if (arreglo_imagenes.length > 1) {

            const config = {
                headers: { "content-type": `${arreglo_imagenes[0].type}` },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    setPorcentaje(Math.floor((loaded * 100) / total));
                    //console.log(`${loaded}kb de ${total} | ${percent}%`);
                }
            };

            const formData = new FormData();

            for (let i = 0; i < arreglo_imagenes.length; i++) {
                formData.append('images', arreglo_imagenes[i])
            }

            axios.post(`${UploadImagesService.obtenerURL_API()}` + `/api/prestadores_imagenes/multiple/${id}/Promociones`, formData, config)
                .then((response) => {
                    //console.log(response);
                    if (response.status === 200) {
                        mostrarNotificacion('success', 'Información registrada con exito')
                        setImagenes([]);
                        setNombres([]);
                        setVistaPrevia([]);
                        limpiar();
                    }

                }, error => {
                    mostrarNotificacion('error', 'Error registrando información: ' + error.toString())
                    console.error(error);
                });

        }
    }

    const removeImage = (imagen, nombre) => {
        //console.log('img', imagenes);
        contador_imagenes = contador_imagenes - 1;
        console.log('remove item', contador_imagenes);

        let arreglo_imagenes = [];
        for (let i = 0; i < imagenes.length; i++) {
            for (let j = 0; j < imagenes[i].length; j++) {
                //console.log('name', imagenes[i][j].name);
                if (imagenes[i][j].name !== nombre) {
                    arreglo_imagenes.push(imagenes[i][j]);
                }
            }
        }

        //console.log(arreglo_imagenes);
        setImagenes([]);
        setImagenes(imagenes => [...imagenes, arreglo_imagenes]);

        //setImagenes(arreglo_imagenes);

        setNombres(nombres.filter(item => item !== nombre))
        setVistaPrevia(vista_previa.filter(item => item !== imagen))
    }

    const limpiar = (event) => {
        setCodigoPrestador(0);

        setPassword('');

        setNombre('');

        setDireccion('');

        setCiudad('');

        setMunicipio('');

        setEstado('');

        setPais('');

        contador_imagenes = 0;

    }

    const handleNext = (event) => {

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setPrestador([])
        //console.log(activeStep);

        switch (activeStep) {
            case 3:

                // if (imagenes.length === 0) {
                //     mostrarNotificacion('error', "Debe seleccionar al menos una imagen para iniciar la carga")
                //     return
                // }

                uploadFiles(codigo_prestador);

                //console.log(prestador)
                break
            default:
                break
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const validarPrestador = () => {

        if (codigo_prestador === 0 || codigo_prestador.length === 0) {
            mostrarNotificacion('error', 'El código ingresado debe ser un dígito entero mayor a 0')
            return
        }

        if (password.length === 0) {
            mostrarNotificacion('error', 'El campo Clave no puede estar vacío')
            return
        }

        setOpenLoading(true);
        let data = [];
        data.push({
            id: codigo_prestador,
            clave: password
        })

        ProviderService.validarPrestador(data[0])
            .then((response) => {
                setOpenLoading(false);
                if (response.data.length === 0) {
                    mostrarNotificacion('error', 'El código de prestador ingresado no existe.')
                    return
                }

                if (response.data.message !== undefined) {
                    mostrarNotificacion('error', response.data.message)
                    return
                }

                setNombre(response.data[0].nombre);
                setDireccion(response.data[0].direccion);
                setCiudad(response.data[0].codigo_ciudad);
                setMunicipio(response.data[0].codigo_municipio);
                setEstado(response.data[0].nombre_estado);
                setPais(response.data[0].codigo_pais);

                handleNext();

            },
                error => {
                    setOpenLoading(false);
                    mostrarNotificacion('error', 'Error obteniendo información: ' + error.toString())
                    console.log(error.toString())
                })
    }

    useEffect(() => {
        Data
            .ciudades()
            .then((response) => {
                setCiudades(response.data)
            }, err => {

            });
    }, [])
    // useEffect(() => {

    // }, [nombres, imagenes])

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const mostrarNotificacion = (tipo, mensaje) => {
        setOpen(true);
        setColorMessage(tipo)
        setMessage(mensaje)
    }

    const validarCampos = (step) => {
        switch (step) {
            case 0:

                return true;

            case 1:

                return true;

            case 2:

                return true;

            case 3:

                return true;

            case 5:

                return true;

            case 6:

                return true;

        }
    }

    function FacebookCircularProgress(props) {
        return (
            <Box sx={{ position: 'relative' }}>
                <CircularProgress
                    variant="determinate"
                    sx={{
                        color: (theme) =>
                            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                    }}
                    size={80}
                    thickness={4}
                    {...props}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                        animationDuration: '550ms',
                        position: 'absolute',
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                        },
                    }}
                    size={80}
                    thickness={4}
                    {...props}
                />
            </Box>
        );
    }

    const onKeyDown = (value) => {

        if (parseInt(value) === 0 || value.length === 0) {
            mostrarNotificacion('error', 'El código ingresado debe ser un dígito entero mayor a 0')
            return
        }

        setOpenLoading(true);
        ProviderService.consultar(value)
            .then((response) => {
                setOpenLoading(false);
                if (response.data.length === 0) {
                    mostrarNotificacion('error', 'El código de prestador ingresado no existe.')
                    return
                }

                setNombre(response.data[0].nombre);
                setDireccion(response.data[0].direccion);
                setCiudad(response.data[0].codigo_ciudad);
                setMunicipio(response.data[0].codigo_municipio);
                setEstado(response.data[0].codigo_estado);
                setPais(response.data[0].codigo_pais);

            },
                error => {
                    setOpenLoading(false);
                    mostrarNotificacion('error', 'Error obteniendo información: ' + error.toString())
                    console.log(error.toString())
                })
    }

    return (
        <Container component="section" id="contenedor"
            sx={{
                marginTop: 12,
                background: '#eee',
            }}
        >
            <Recommended titulo={'Agregar promociones'} />
            <Box sx={{ maxWidth: '100%' }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel
                                optional={
                                    index === 3 ? (
                                        <Typography variant="caption">Último paso</Typography>
                                    ) : null
                                }
                            >
                                {step.label}
                            </StepLabel>
                            <StepContent>
                                {step.id === 1 ?
                                    <ValidateProvider
                                        password={password}
                                        showPassword={showPassword}
                                        codigo_prestador={codigo_prestador}
                                        changeHandler={changeHandler}
                                        handleClickShowPassword={handleClickShowPassword}
                                        handleMouseDownPassword={handleMouseDownPassword}
                                        onKeyDown={validarPrestador}
                                    />
                                    :
                                    step.id === 2 ?
                                        <DataBasicProvider
                                            codigo_prestador={codigo_prestador}
                                            nombre={nombre}
                                            direccion={direccion}
                                            ciudad={ciudad}
                                            municipio={municipio}
                                            estado={estado}
                                            pais={pais}
                                            campo_visible={true}
                                            desactivar_campos={true}
                                            desactivar_busqueda={true}
                                            changeHandler={changeHandler}
                                            onKeyDown={onKeyDown}
                                        />
                                        :
                                        step.id === 3 ?
                                            <DataPromotion
                                                precio={precio}
                                                ciudades={ciudades}
                                                ciudad_origen={ciudad_origen}
                                                ciudad_destino={ciudad_destino}
                                                descripcion={descripcion}
                                                changeHandler={changeHandler}
                                                changeHandler_CO={changeHandler_CO}
                                                changeHandler_CD={changeHandler_CD}
                                            />
                                            :
                                            step.id === 4 ?
                                                <ImagesProvider
                                                    imagenes={imagenes}
                                                    vista_previa={vista_previa}
                                                    nombres={nombres}
                                                    porcentaje={porcentaje}
                                                    changeHandler={changeHandler}
                                                    uploadFiles={uploadFiles}
                                                    removeImage={removeImage}
                                                />
                                                :
                                                null
                                }

                                <Box sx={{ mb: 2 }}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={styleButtonNext(index)}
                                            disabled={contador_imagenes === 0 && index === 3}
                                        >
                                            {index === steps.length - 1 ? 'Finalizar' : 'Continuar'}
                                        </Button>
                                        <LoadingButton
                                            loading={openLoading}
                                            loadingPosition="start"
                                            onClick={validarPrestador}
                                            startIcon={<ImportContactsTwoToneIcon />}
                                            variant="outlined"
                                            sx={styleButtonBack(index)}
                                        >
                                            Validar
                                        </LoadingButton>
                                        <Button
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Atrás
                                        </Button>
                                    </div>
                                </Box>
                            </StepContent>

                        </Step>

                    ))}
                </Stepper>
                {
                    activeStep === steps.length && porcentaje !== 100
                        ?
                        <Paper square elevation={0} sx={{ p: 3 }}>
                            <h1>No cerrar hasta tanto no finalice la carga</h1>
                            <Typography>Progreso total:</Typography>
                            <Box sx={{ width: '100%' }}>
                                <LinearProgressWithLabel value={porcentaje} />
                            </Box>
                        </Paper>
                        : null
                }
                {activeStep === steps.length && porcentaje === 100 && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>Todos los pasos completados</Typography>
                        <h1>Actualización finalizada. {nombre}.</h1>
                        {/* <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Agregar otro
            </Button> */}
                    </Paper>
                )}
            </Box>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleCloseSnackBar} variant="filled" anchorOrigin={{ vertical, horizontal }}>
                <Alert onClose={handleCloseSnackBar} severity={colorMessage} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Container>
    );
}