import * as React from 'react';
import { useState, useEffect } from 'react';
import withRoot from './modules/withRoot/withRoot';

import Footer from './modules/components/Footer'
import Header from './modules/components/Header'

import FloatingWhatsApp from 'react-floating-whatsapp-body';
import Data from './servicio/Data';

import { DivUI, H1, HeaderUI } from './modules/components/BaseComponents'

import ChatBotReact from './modules/components/ChatBotReact';

import { DivUIPrincipal } from './modules/components/BaseComponents'

import AppBar from '@mui/material/AppBar';

import LayoutCenter from './modules/components/LayoutCenter'

import Backdrop from '@mui/material/Backdrop';
import { Box, Container } from '@mui/material';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';

import { Alert as MuiAlert, Snackbar } from '@mui/material'

import InsertComments from './modules/components/InsertComment';
import CommentsService from './servicio/CommentsService';

import { HashRouter, Route, Routes, Switch } from 'react-router-dom';

import SkeletonCard from "./modules/components/SkeletonCard"

import HomeLayout from './HomeLayout';

import TabProvider from './modules/components/TabProvider'

import Home from './modules/pages/Home'
import StepperProviderPromotion from './modules/components/StepperProviderPromotion';

import Publications from './modules/pages/Publications/Publications';
import PublicationPreview from './modules/pages/Publications/PublicationPreview'
import PostsByState from './modules/pages/Publications/PostsByState';
import ProviderProfile from './modules/pages/provider/ProviderProfile';
import ProvidersByCityAndService from './modules/pages/provider/ProvidersByCityAndService';

const variants = ['h1', 'h3', 'body1', 'caption'];


function App() {

  return (
    <React.Fragment>      
      {/* <Backdrop
        sx={{ color: '#fff', zIndex: 1000 }}
        open={openLoading}
      >
      <Route path="/home" element={<Home />} />
        <FacebookCircularProgress />
      </Backdrop> */}
      <Routes>
        <Route path="/" element={<HomeLayout />} />        
        <Route path="/home" element={<Home />} />
        <Route path="/preregistro" element={<TabProvider />} />
        <Route path="/registrar/promociones" element={<StepperProviderPromotion />} />
        <Route path="/full-day-viajes-excursiones-en-venezuela" element={<PostsByState />} />
        <Route path="/full-day-viajes-excursiones-en-venezuela/:url" element={<Publications />} />
        <Route path="/full-day-viajes-excursiones-en-venezuela/:url/:sub_url" element={<PublicationPreview />} />
        <Route path="/:url" element={<ProvidersByCityAndService />} />
        <Route path="/:url/:tipo_servicio" element={<ProvidersByCityAndService />} />
        <Route path="/perfil/:url_prestador" element={<ProviderProfile />} />
        <Route path="/perfil/:url_prestador/:sub_url" element={<PublicationPreview />} />
      </Routes>
      <Footer />
    </React.Fragment>
  );
}

export default withRoot(App);

