import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';

import Filter1TwoToneIcon from '@mui/icons-material/Filter1TwoTone';
import Filter2TwoToneIcon from '@mui/icons-material/Filter2TwoTone';
import Filter3TwoToneIcon from '@mui/icons-material/Filter3TwoTone';
import Filter4TwoToneIcon from '@mui/icons-material/Filter4TwoTone';
import Filter5TwoToneIcon from '@mui/icons-material/Filter5TwoTone';
import Filter6TwoToneIcon from '@mui/icons-material/Filter6TwoTone';
import Filter7TwoToneIcon from '@mui/icons-material/Filter7TwoTone';
import Filter8TwoToneIcon from '@mui/icons-material/Filter8TwoTone';
import Filter9TwoToneIcon from '@mui/icons-material/Filter9TwoTone';
import Filter9PlusTwoToneIcon from '@mui/icons-material/Filter9PlusTwoTone';

import '../../../../css/provider_items.css';

import {
    Container,
    List,
    ListItem,
    Alert,
    Typography
} from '@mui/material'

import { makeStyles } from '@mui/styles';
import { IndeterminateCheckBoxOutlined } from '@mui/icons-material';

const obtenerColorAleatorio = () => {
    let hue = Math.floor(Math.random() * 360);
    return 'hsl(' + hue + ', 100%, 80%)';
}

const obtenerIcono = (index) => {
    let indice = index + 1;
    switch (indice) {
        case 1:
            return <Filter1TwoToneIcon sx={{ color: 'black', }} />;

        case 2:
            return <Filter2TwoToneIcon fontSize='large' sx={{ color: 'black', }} />;

        case 3:
            return <Filter3TwoToneIcon fontSize='large' sx={{ color: 'black', }} />;

        case 4:
            return <Filter4TwoToneIcon fontSize='large' sx={{ color: 'black', }} />;

        case 5:
            return <Filter5TwoToneIcon fontSize='large' sx={{ color: 'black', }} />;

        case 6:
            return <Filter6TwoToneIcon fontSize='large' sx={{ color: 'black', }} />;

        case 7:
            return <Filter7TwoToneIcon fontSize='large' sx={{ color: 'black', }} />;

        case 8:
            return <Filter8TwoToneIcon fontSize='large' sx={{ color: 'black', }} />;

        case 9:
            return <Filter9TwoToneIcon fontSize='large' sx={{ color: 'black', }} />;

        default:
            return <Filter9PlusTwoToneIcon fontSize='large' sx={{ color: 'black', }} />;
    }
}

export default function ProviderItems(props) {

    const useStyles = makeStyles(() => ({
        alertInfo: {
            '&:hover': {
                transform: 'scale(1.0001)',
                boxShadow: `0 1px 3px 0`,
                backgroundColor: '#D1E9F6',
                '& .css-132jxnu-MuiPaper-root': {
                    backgroundColor: '#D1E9F6'
                }
            },
        },
        timeline__event__date: {
            color: 'black',
            fontSize: '1.5rem',
            fontWeight: '600',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            whiteSpace: 'nowrap',
            padding: '0 20px',
            borderRadius: '6px 0 0 6px',
        },
        list: {
            '& .MuiSvgIcon-root': { fontSize: '28px !important' }
        }
    }));

    const classes = useStyles();

    return (
        <Container sx={{ paddingTop: 2, paddingRight: 0 }}>
            {
                props.items !== undefined ?
                    props.items.length !== 0 ?
                        <List className={classes.list} sx={{ paddingLeft: 0, width: '100%', maxWidth: '100%', bgcolor: 'transparent' }}>
                            <div class="timeline">
                                {props.items.map((servicio, index) => (
                                    <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                                        <div class="timeline__event__icon ">
                                            {obtenerIcono(index)}
                                        </div>
                                        <div className={classes.timeline__event__date} style={{ background: obtenerColorAleatorio() }}>
                                            {'Precio: ' + servicio.precio + '$'}
                                        </div>
                                        <div class="timeline__event__content ">
                                            {/* <div class="timeline__event__title">
                                                {'Servicio' + index + 1}
                                            </div> */}
                                            <div class="timeline__event__description">
                                                <p>{servicio.nombre}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </List>
                        : null : null
            }
            {/* <div class="timeline">
                <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                    <div class="timeline__event__icon ">
                        <i class="lni-cake"></i>

                    </div>
                    <div class="timeline__event__date">
                        20-08-2019
                    </div>
                    <div class="timeline__event__content ">
                        <div class="timeline__event__title">
                            Birthday
                        </div>
                        <div class="timeline__event__description">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel, nam! Nam eveniet ut aliquam ab asperiores, accusamus iure veniam corporis incidunt reprehenderit accusantium id aut architecto harum quidem dolorem in!</p>
                        </div>
                    </div>
                </div>
                <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                    <div class="timeline__event__icon">
                        <i class="lni-burger"></i>

                    </div>
                    <div class="timeline__event__date">
                        20-08-2019
                    </div>
                    <div class="timeline__event__content">
                        <div class="timeline__event__title">
                            Lunch
                        </div>
                        <div class="timeline__event__description">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel, nam! Nam eveniet ut aliquam ab asperiores, accusamus iure veniam corporis incidunt reprehenderit accusantium id aut architecto harum quidem dolorem in!</p>
                        </div>
                    </div>
                </div>
                <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                    <div class="timeline__event__icon">
                        <i class="lni-slim"></i>

                    </div>
                    <div class="timeline__event__date">
                        20-08-2019
                    </div>
                    <div class="timeline__event__content">
                        <div class="timeline__event__title">
                            Exercise
                        </div>
                        <div class="timeline__event__description">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel, nam! Nam eveniet ut aliquam ab asperiores, accusamus iure veniam corporis incidunt reprehenderit accusantium id aut architecto harum quidem dolorem in!</p>
                        </div>

                    </div>
                </div>
                <div class="timeline__event animated fadeInUp timeline__event--type1">
                    <div class="timeline__event__icon">
                        <i class="lni-cake"></i>

                    </div>
                    <div class="timeline__event__date">
                        20-08-2019
                    </div>
                    <div class="timeline__event__content">
                        <div class="timeline__event__title">
                            Birthday
                        </div>
                        <div class="timeline__event__description">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel, nam! Nam eveniet ut aliquam ab asperiores, accusamus iure veniam corporis incidunt reprehenderit accusantium id aut architecto harum quidem dolorem in!</p>
                        </div>
                    </div>
                </div>

            </div> */}
        </Container>
    );
}

