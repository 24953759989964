import * as React from 'react';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
    Backdrop,
    CircularProgress,
    circularProgressClasses,
} from '@mui/material'

import { styled } from '@mui/material/styles';
import { Container, Alert as MuiAlert, Snackbar } from '@mui/material';
import TextField from '@mui/material/TextField';
import { SocialMediaProvider, TypeProvider, ServicesProvider } from './RegisterProvider';
import DataBasicProvider from './RegisterProvider';
import ProviderService from '../../servicio/ProviderService';
import { ImagesProvider, ImageFrontPage } from './RegisterProvider';
import UploadImagesService from '../../servicio/UploadImagesService';
import { useEffect } from 'react';

import Recommended from '../components/Recommended';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress'

const Img = styled('img')({
    margin: 0,
    height: '-webkit-fill-available',
    width: '100%',
    objectFit: 'cover',
    display: 'flex',
});

const steps = [
    {
        id: 1,
        label: 'Datos basicos',
        description: `Datos basicos`,
    },
    {
        id: 2,
        label: 'Comunicaciones',
        description: `Comunicaciones`,
    },
    {
        id: 3,
        label: 'Servicios',
        description: `Servicios`,
    },
    {
        id: 4,
        label: 'Fotografía portada',
        description: `Fotografía portada`,
    },
    {
        id: 5,
        label: 'Fotografías',
        description: `Fotografías`,
    },
];

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

let contador_imagenes = 0

export default function StepperProviderUpdate() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [prestador, setPrestador] = React.useState([]);
    const [nombre, setNombre] = React.useState('');
    const [direccion, setDireccion] = React.useState('');
    const [ciudad, setCiudad] = React.useState('');
    const [municipio, setMunicipio] = React.useState('');
    const [estado, setEstado] = React.useState('');
    const [pais, setPais] = React.useState('');
    const [ws, setWs] = React.useState('');
    const [ig, setIg] = React.useState('');
    const [fb, setFb] = React.useState('');
    const [www, setWww] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [servicio1, setServicio1] = React.useState('');
    const [servicio2, setServicio2] = React.useState('');
    const [servicio3, setServicio3] = React.useState('');
    const [etiqueta, setEtiqueta] = React.useState('');

    const [imagenes, setImagenes] = React.useState([]);
    const [vista_previa, setVistaPrevia] = React.useState([]);
    const [nombres, setNombres] = React.useState([]);

    const [portada, setPortada] = React.useState([]);
    const [vista_previa_portada, setVistaPreviaPortada] = React.useState([]);
    const [nombre_portada, setNombrePortada] = React.useState([]);

    const [open, setOpen] = React.useState(false)
    const [vertical, setVertical] = React.useState('bottom');
    const [horizontal, setHorizontal] = React.useState('center');
    const [colorMessage, setColorMessage] = React.useState('success');
    const [message, setMessage] = React.useState('')

    const [openLoading, setOpenLoading] = React.useState(false);

    const [tipoprestador, setTipoPrestador] = React.useState({
        todos: true,
        hotel: false,
        restaurant: false,
        paseos: false,
        operador: false,
        buses: false,
        toldos: false,
        seguros: false
    });

    const [porcentaje, setPorcentaje] = React.useState(0);

    const [codigo_prestador, setCodigoPrestador] = React.useState(0);

    const changeHandler = (event) => {
        event.preventDefault();

        switch (event.target.name) {
            case 'codigo_prestador':
                setCodigoPrestador(event.target.value);
                break;
            case 'nombre':
                setNombre(event.target.value);
                break;
            case 'direccion':
                setDireccion(event.target.value);
                break;
            case 'ciudad':
                setCiudad(event.target.value);
                break;
            case 'municipio':
                setMunicipio(event.target.value);
                break;
            case 'estado':
                setEstado(event.target.value);
                break;
            case 'pais':
                setPais(event.target.value);
                break;
            case 'ws':
                setWs(event.target.value);
                break;
            case 'ig':
                setIg(event.target.value);
                break;
            case 'fb':
                setFb(event.target.value);
                break;
            case 'www':
                setWww(event.target.value);
                break;
            case 'email':
                setEmail(event.target.value);
                break;
            case 'servicio1':
                setServicio1(event.target.value);
                break;
            case 'servicio2':
                setServicio2(event.target.value);
                break;
            case 'servicio3':
                setServicio3(event.target.value);
                break;
            case 'etiqueta':
                setEtiqueta(event.target.value);
                break;
            case 'portada':

                if (event.target.files) {

                    // if (event.target.files[0].size > 500) {
                    //   mostrarNotificacion('error', 'El tamaño del archivo supera los 500KB');
                    //   return
                    // }

                    setPortada(event.target.files)

                    /* Get files in array form */
                    //console.log(event.target.files);
                    const files = Array.from(event.target.files);

                    /* Map each file to a promise that resolves to an array of image URI's */
                    const archivos = [];

                    Promise.all(files.map(file => {
                        archivos.push(file.name);
                        return (new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.addEventListener('load', (ev) => {
                                resolve(ev.target.result);
                            });
                            reader.addEventListener('error', reject);
                            reader.readAsDataURL(file);
                        }));
                    }))
                        .then(images => {

                            /* Once all promises are resolved, update state with image URI array */
                            var joined_1 = vista_previa_portada.concat(images);
                            var joined_2 = nombre_portada.concat(archivos);

                            setVistaPreviaPortada(joined_1)
                            setNombrePortada(joined_2)

                        }, error => {
                            console.error(error);
                        });
                }

                break;

            case 'imagenes':
                //console.log(portada.length);
                if (event.target.files) {
                    //console.log('event.target.files.length', event.target.files.length);
                    if (event.target.files.length > 11) {
                        mostrarNotificacion('error', 'Solo es posible seleccionar máximo 12 imagenes');
                        return
                    }

                    contador_imagenes += event.target.files.length;
                    console.log(contador_imagenes);
                    if (contador_imagenes > 11) {
                        contador_imagenes = contador_imagenes - event.target.files.length;
                        mostrarNotificacion('error', 'Ha excedido el número de imagenes(12)');
                        return
                    }

                    setImagenes(imagenes => [...imagenes, event.target.files])

                    /* Get files in array form */
                    //console.log(event.target.files);
                    const files = Array.from(event.target.files);

                    /* Map each file to a promise that resolves to an array of image URI's */
                    const archivos = [];

                    Promise.all(files.map(file => {
                        archivos.push(file.name);
                        return (new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.addEventListener('load', (ev) => {
                                resolve(ev.target.result);
                            });
                            reader.addEventListener('error', reject);
                            reader.readAsDataURL(file);
                        }));
                    }))
                        .then(images => {

                            /* Once all promises are resolved, update state with image URI array */
                            var joined_1 = vista_previa.concat(images);
                            var joined_2 = nombres.concat(archivos);

                            setVistaPrevia(joined_1)
                            setNombres(joined_2)

                        }, error => {
                            console.error(error);
                        });
                }
                break;
        }

    }

    const changeHandlerChekbox = (event) => {
        setTipoPrestador({
            ...tipoprestador,
            [event.target.name]: event.target.checked,
        });
    }

    const uploadFiles = (id) => {

        let arreglo_imagenes = [];
        for (let i = 0; i < imagenes.length; i++) {
            for (let j = 0; j < imagenes[i].length; j++) {
                arreglo_imagenes.push(imagenes[i][j])
            }
        }

        if (portada.length !== 0) {

            const config_portada = {
                headers: { "content-type": `${portada[0].type}` }
            };
            const formData_Portada = new FormData();
            formData_Portada.append('image', portada[0])

            let datos_portada = [];
            datos_portada.push({
                codigo_prestador: id,
                ubicacion: 'General',
                tipo_imagen: 'P',
            })

            axios.post(`${UploadImagesService.obtenerURL_API()}` + `/api/prestadores_imagenes/simple/${JSON.stringify(datos_portada[0])}`, formData_Portada, config_portada)
                .then((response) => {
                    //console.log(response);
                    if (response.status === 200) {
                        setPortada([]);
                        setNombrePortada([]);
                        setVistaPreviaPortada([]);
                        if (arreglo_imagenes.length === 0) {
                            setPorcentaje(100);
                            mostrarNotificacion('success', 'Información registrada con exito')
                            limpiar()
                        }
                    }

                }, error => {
                    mostrarNotificacion('error', 'Error subiendo foto de portada: ' + error.toString())
                    console.error(error);
                });

        }

        if (arreglo_imagenes.length === 1) {

            const config = {
                headers: { "content-type": `${arreglo_imagenes[0].type}` },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    setPorcentaje(Math.floor((loaded * 100) / total));
                    //console.log(`${loaded}kb de ${total} | ${percent}%`);
                }
            };

            const formData = new FormData();

            formData.append('image', arreglo_imagenes[0])

            let datos_detalle = [];
            datos_detalle.push({
                codigo_prestador: id,
                ubicacion: 'General',
                tipo_imagen: 'D',
            })

            axios.post(`${UploadImagesService.obtenerURL_API()}` + `/api/prestadores_imagenes/simple/${JSON.stringify(datos_detalle[0])}`, formData, config)
                .then((response) => {
                    //console.log(response);
                    if (response.status === 200) {
                        mostrarNotificacion('success', 'Información registrada con exito')
                        setImagenes([]);
                        setNombres([]);
                        setVistaPrevia([]);
                        limpiar();
                    }

                }, error => {
                    mostrarNotificacion('error', 'Error registrando información: ' + error.toString())
                    console.error(error);
                });

        } else if (arreglo_imagenes.length > 1) {

            const config = {
                headers: { "content-type": `${arreglo_imagenes[0].type}` },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    setPorcentaje(Math.floor((loaded * 100) / total));
                    //console.log(`${loaded}kb de ${total} | ${percent}%`);
                }
            };

            const formData = new FormData();

            for (let i = 0; i < arreglo_imagenes.length; i++) {
                formData.append('images', arreglo_imagenes[i])
            }

            axios.post(`${UploadImagesService.obtenerURL_API()}` + `/api/prestadores_imagenes/multiple/${id}/General`, formData, config)
                .then((response) => {
                    //console.log(response);
                    if (response.status === 200) {
                        mostrarNotificacion('success', 'Información registrada con exito')
                        setImagenes([]);
                        setNombres([]);
                        setVistaPrevia([]);
                        limpiar();
                    }

                }, error => {
                    mostrarNotificacion('error', 'Error registrando información: ' + error.toString())
                    console.error(error);
                });

        }
    }

    const removeImage = (imagen, nombre) => {
        //console.log('img', imagenes);
        contador_imagenes = contador_imagenes - 1;
        console.log('remove item', contador_imagenes);

        let arreglo_imagenes = [];
        for (let i = 0; i < imagenes.length; i++) {
            for (let j = 0; j < imagenes[i].length; j++) {
                //console.log('name', imagenes[i][j].name);
                if (imagenes[i][j].name !== nombre) {
                    arreglo_imagenes.push(imagenes[i][j]);
                }
            }
        }

        //console.log(arreglo_imagenes);
        setImagenes([]);
        setImagenes(imagenes => [...imagenes, arreglo_imagenes]);

        //setImagenes(arreglo_imagenes);

        setNombres(nombres.filter(item => item !== nombre))
        setVistaPrevia(vista_previa.filter(item => item !== imagen))
    }

    const removeImageFrontPage = (imagen, nombre) => {

        let arreglo_imagenes = [];
        for (let i = 0; i < portada.length; i++) {

            //console.log('name', imagenes[i][j].name);
            if (portada[i].name !== nombre) {
                arreglo_imagenes.push(portada[i]);
            }

        }

        //console.log(arreglo_imagenes);
        setPortada([]);
        setPortada(portada => [...portada, arreglo_imagenes]);

        //setImagenes(arreglo_imagenes);

        setNombrePortada(nombre_portada.filter(item => item !== nombre))
        setVistaPreviaPortada(vista_previa_portada.filter(item => item !== imagen))
    }

    const limpiar = (event) => {
        setCodigoPrestador(0);

        setNombre('');

        setDireccion('');

        setCiudad('');

        setMunicipio('');

        setEstado('');

        setPais('');

        setWs('');

        setIg('');

        setFb('');

        setWww('');

        setEmail('');

        setServicio1('');

        setServicio2('');

        setServicio3('');

        setEtiqueta('');

        setTipoPrestador({
            ...tipoprestador,
            todos: true,
            hotel: false,
            restaurant: false,
            paseos: false,
            operador: false,
            buses: false,
            toldos: false,
            seguros: false
        });

        contador_imagenes = 0;

    }

    const handleNext = (event) => {

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setPrestador([])
        //console.log(activeStep);

        switch (activeStep) {
            case 4:

                let comunicaciones = []
                let servicios = []

                if (ws.length !== 0) {
                    comunicaciones.push({ tipo: 'ws', cuenta: ws })
                }

                if (fb.length !== 0) {
                    comunicaciones.push({ tipo: 'fb', cuenta: fb })
                }

                if (ig.length !== 0) {
                    comunicaciones.push({ tipo: 'ig', cuenta: ig })
                }

                if (www.length !== 0) {
                    comunicaciones.push({ tipo: 'www', cuenta: www })
                }

                if (email.length !== 0) {
                    comunicaciones.push({ tipo: 'email', cuenta: email })
                }

                if (servicio1.length !== 0) {
                    servicios.push(servicio1);
                }

                if (servicio2.length !== 0) {
                    servicios.push(servicio2);
                }

                if (servicio3.length !== 0) {
                    servicios.push(servicio3);
                }

                //console.log('CODIGO', codigo_prestador);

                prestador.push({
                    codigo_prestador: codigo_prestador,
                    comunicaciones: comunicaciones,
                    servicios: servicios,
                })

                //console.log(prestador);

                if (prestador[0].comunicaciones.length === 0 && prestador[0].servicios.length === 0 && imagenes.length === 0 && portada.length === 0) {
                    handleReset();
                } else if (prestador[0].comunicaciones.length !== 0 || prestador[0].servicios.length !== 0) {
                    ProviderService
                        .agregardatos(prestador)
                        .then((response) => {

                            if (imagenes.length !== 0 || portada.length !== 0) {
                                uploadFiles(codigo_prestador);
                            } else {
                                setPorcentaje(100);
                                mostrarNotificacion('success', 'Información actualizada con exito')
                                limpiar()
                            }

                        },
                            error => {
                                mostrarNotificacion('error', 'Error actualizando información: ' + error.toString())
                                console.log(error.toString())
                            })

                    console.log(prestador);
                } else if (imagenes.length !== 0 || portada.length !== 0) {
                    uploadFiles(codigo_prestador);
                }

                //console.log(prestador)
                break
            default:
                break
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {


    }, [nombres, imagenes])

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const mostrarNotificacion = (tipo, mensaje) => {
        setOpen(true);
        setColorMessage(tipo)
        setMessage(mensaje)
    }

    const validarCampos = (step) => {
        switch (step) {
            case 0:

                return true;

            case 1:

                return true;

            case 2:

                return true;

            case 3:

                return true;

            case 5:

                return true;

            case 6:

                return true;

        }
    }

    function FacebookCircularProgress(props) {
        return (
            <Box sx={{ position: 'relative' }}>
                <CircularProgress
                    variant="determinate"
                    sx={{
                        color: (theme) =>
                            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                    }}
                    size={80}
                    thickness={4}
                    {...props}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                        animationDuration: '550ms',
                        position: 'absolute',
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                        },
                    }}
                    size={80}
                    thickness={4}
                    {...props}
                />
            </Box>
        );
    }

    const onKeyDown = (value) => {

        if (parseInt(value) === 0 || value.length === 0) {
            mostrarNotificacion('error', 'El código ingresado debe ser un dígito entero mayor a 0')
            return
        }

        setOpenLoading(true);
        ProviderService.consultar(value)
            .then((response) => {
                setOpenLoading(false);
                if (response.data.length === 0) {
                    mostrarNotificacion('error', 'El código de prestador ingresado no existe.')
                    return
                }

                setNombre(response.data[0].nombre);
                setDireccion(response.data[0].direccion);
                setCiudad(response.data[0].codigo_ciudad);
                setMunicipio(response.data[0].codigo_municipio);
                setEstado(response.data[0].codigo_estado);
                setPais(response.data[0].codigo_pais);

            },
                error => {
                    setOpenLoading(false);
                    mostrarNotificacion('error', 'Error obteniendo información: ' + error.toString())
                    console.log(error.toString())
                })
    }

    return (
        <Container component="section" id="contenedor"
            sx={{
                background: '#eee',
            }}
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: 1000 }}
                open={openLoading}
            >
                <FacebookCircularProgress />
            </Backdrop>
            <Recommended titulo={'Agregar información a prestadores'} />
            <Box sx={{ maxWidth: '100%' }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel
                                optional={
                                    index === 6 ? (
                                        <Typography variant="caption">Último paso</Typography>
                                    ) : null
                                }
                            >
                                {step.label}
                            </StepLabel>
                            <StepContent>
                                {step.id === 1 ?
                                    <DataBasicProvider
                                        codigo_prestador={codigo_prestador}
                                        nombre={nombre}
                                        direccion={direccion}
                                        ciudad={ciudad}
                                        municipio={municipio}
                                        estado={estado}
                                        pais={pais}
                                        campo_visible={true}
                                        desactivar_campos={true}
                                        desactivar_busqueda={false}
                                        changeHandler={changeHandler}
                                        onKeyDown={onKeyDown}
                                    />
                                    :
                                    step.id === 2 ?
                                        <SocialMediaProvider
                                            ws={ws}
                                            fb={fb}
                                            ig={ig}
                                            www={www}
                                            email={email}
                                            changeHandler={changeHandler}
                                        />
                                        :
                                        step.id === 3 ?
                                            <ServicesProvider
                                                servicio1={servicio1}
                                                servicio2={servicio2}
                                                servicio3={servicio3}
                                                changeHandler={changeHandler} />
                                            :
                                            step.id === 4 ?
                                                <ImageFrontPage
                                                    imagenes={portada}
                                                    vista_previa={vista_previa_portada}
                                                    nombres={nombre_portada}
                                                    changeHandler={changeHandler}
                                                    removeImageFrontPage={removeImageFrontPage}
                                                />
                                                :
                                                step.id === 5 ?
                                                    <ImagesProvider
                                                        imagenes={imagenes}
                                                        vista_previa={vista_previa}
                                                        nombres={nombres}
                                                        porcentaje={porcentaje}
                                                        changeHandler={changeHandler}
                                                        uploadFiles={uploadFiles}
                                                        removeImage={removeImage}
                                                    />
                                                    :
                                                    null
                                }

                                <Box sx={{ mb: 2 }}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                            disabled={(nombre === "" || nombre.length === 0 || nombre === undefined || nombre === null)}
                                        >
                                            {index === steps.length - 1 ? 'Finalizar' : 'Continuar'}
                                        </Button>
                                        <Button
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Atrás
                                        </Button>
                                    </div>
                                </Box>
                            </StepContent>

                        </Step>

                    ))}
                </Stepper>
                {
                    activeStep === steps.length && porcentaje !== 100
                        ?
                        <Paper square elevation={0} sx={{ p: 3 }}>
                            <h1>No cerrar hasta tanto no finalice la carga</h1>
                            <Typography>Progreso total:</Typography>
                            <Box sx={{ width: '100%' }}>
                                <LinearProgressWithLabel value={porcentaje} />
                            </Box>
                        </Paper>
                        : null
                }
                {activeStep === steps.length && porcentaje === 100 && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>Todos los pasos completados</Typography>
                        <h1>Actualización finalizada. {nombre}.</h1>
                        {/* <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Agregar otro
            </Button> */}
                    </Paper>
                )}
            </Box>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleCloseSnackBar} variant="filled" anchorOrigin={{ vertical, horizontal }}>
                <Alert onClose={handleCloseSnackBar} severity={colorMessage} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Container>
    );
}