import * as React from 'react';

// nodejs library that concatenates classes
import classNames from "classnames";
import { styled } from '@mui/material/styles';

import {
    Alert,
    AlertTitle,
    Container,
    Divider,
    useMediaQuery
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';

import ProviderService from '../../../servicio/ProviderService';

import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import FunctionsCommon from '../../functions/FunctionsCommon';

import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import AssistantDirectionTwoToneIcon from '@mui/icons-material/AssistantDirectionTwoTone';
import Header from '../../components/Header';
import PlaneTicketCardDemo from '../../components/PlaneTicketCardDemo';
import Ticket from '../../components/ticket/Ticket';

import Parallax from "../../components/Provider/Parallax/Parallax.js";
import ecosistemas from "../../../assets/images/ecosistemas.png"

import GridContainer from "../../components/Provider/Grid/GridContainer.js";
import GridItem from "../../components/Provider/Grid/GridItem.js";
import styles from "../../components/Provider/profilePage.js";

import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import ConnectWithoutContactTwoToneIcon from '@mui/icons-material/ConnectWithoutContactTwoTone';
import Title from '../../components/Provider/Title/Title';

import imagen from '../../../assets/images/discount-ticket.png'
import SocialMediaSection from '../../components/Provider/SocialMedia/SocialMediaSection';

export function PromotionsPreview(props) {
    const params = useParams();
    const query = useLocation();
    const [promo, setPromo] = React.useState([]);
    const [codigo_prestador, setCodigo_Prestador] = React.useState(0);
    const [nombre_imagen, setNombre_imagen] = React.useState('');
    const [tipo_imagen, setTipo_imagen] = React.useState('');
    const [precio, setPrecio] = React.useState(0)
    const [imagenes, setImagenes] = React.useState([])
    const [imagen_principal, setImagen_Principal] = React.useState('');
    const [nombre, setNombre] = React.useState('');

    const [comunicaciones, setComunicaciones] = React.useState([]);
    const [ciudad_origen, setCiudad_Origen] = React.useState([]);

    const useStyles = makeStyles(styles);

    const { ...rest } = props;
    const classes = useStyles();
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );

    const Img = styled('img')({
        margin: 0,
        height: '-webkit-fill-available',
        minWidth: '400px',
        maxWidth: '425px',
        height: '100%',
        objectFit: 'cover',
        display: 'flex',
        borderRadius: 10
    });

    //const useStyles = makeStyles(styles);

    const m450 = useMediaQuery('(max-width:450px)');

    React.useEffect(() => {
        let arreglo = [];
        arreglo = params.sub_url.split('-');

        let id = arreglo[arreglo.length - 1];

        ProviderService
            .consultar_promociones_por_codigo(id)
            .then((res) => {
                // console.log(id);
                console.log(res.data);
                // console.log(res.data[0].publicaciones[0].nombre_imagen);

                setPromo(res.data[0].publicaciones[0]);
                setImagenes(res.data[0].imagenes)
                setCodigo_Prestador(res.data[0].codigo_prestador)
                setNombre_imagen(res.data[0].publicaciones[0].nombre_imagen);
                setTipo_imagen(res.data[0].publicaciones[0].tipo_imagen);
                setPrecio(res.data[0].publicaciones[0].precio);
                setImagen_Principal(`${FunctionsCommon.obtenerImagenPrincipalJSON(res.data[0].codigo_prestador, res.data[0].imagenes)}`);
                setNombre(res.data[0].nombre);
                setComunicaciones(res.data[0].comunicaciones);

                let arreglo_ciudades = res.data[0].publicaciones[0].ciudad_origen.split('-');
                setCiudad_Origen(arreglo_ciudades);
            })
    }, [])

    return (
        <div>
            <Header />
            <Parallax
                small
                filter
                image={ecosistemas}
            />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div>
                    <div className={classes.container} style={{ paddingLeft: m450 ? 0 : 15 }}>
                        <GridItem>
                            <div className={classes.profile}>
                                <div>
                                    <img src={imagen_principal} className={imageClasses} />
                                </div>
                                <div className={classes.name}>
                                    <h1 className={classes.title}>{promo.descripcion}</h1>
                                </div>
                            </div>
                        </GridItem>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={6} sx={{ paddingTop: 2, paddingBottom: 2, maxWidth: m450 ? '100% !important' : '40% !important' }}>
                                <Img src={`${FunctionsCommon.obtenerImagenDesdeItem(codigo_prestador, promo.nombre_imagen, 'publicaciones', promo.tipo_imagen, 'Prestadores')}`} />
                            </GridItem>
                            <GridItem
                                xs={12} sm={12} md={6}
                                sx={{
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    maxWidth: m450 ? '100% !important' : '60% !important',
                                    flexBasis: 'auto !important',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: 'column'
                                }}>
                                <Title
                                    titulo={'Detalles:'}
                                    icon={<ListAltTwoToneIcon sx={{ color: '#1DB2F5', }} />}
                                >
                                    <PlaneTicketCardDemo
                                        nombre={nombre}
                                        ciudad_origen={ciudad_origen}
                                        codigo_ciudad={promo.codigo_ciudad}
                                        precio={promo.precio}
                                        descripcion={promo.descripcion}
                                        imagen_principal={imagen}
                                        imagen_publicacion={`${FunctionsCommon.obtenerImagenDesdeItem(codigo_prestador, promo.nombre_imagen, 'publicaciones', promo.tipo_imagen, 'Prestadores')}`}
                                    />
                                </Title>

                                <Container sx={{
                                    paddingTop: 4,
                                    paddingBottom: 4,
                                    paddingLeft: '0px !important',
                                    paddingRight: '0px !important'
                                }}>
                                    <Title
                                        titulo={'Detalle de precio:'}
                                        icon={<MonetizationOnTwoToneIcon sx={{ color: '#1DB2F5', }} />}
                                    >
                                        <Alert severity="info" sx={{ width: '100%' }}>
                                            <AlertTitle>Precio por persona</AlertTitle>
                                            <strong>{`${promo.precio}$`}</strong>
                                        </Alert>
                                    </Title>
                                </Container>
                                <Title
                                    titulo={'Contacto:'}
                                    icon={<ConnectWithoutContactTwoToneIcon sx={{ color: '#1DB2F5', }} />}
                                >
                                    <SocialMediaSection
                                        comunicaciones={comunicaciones}
                                        codigo_prestador={codigo_prestador}
                                        nombre={nombre}
                                        imagen={imagenes}
                                        url={window.location.href}
                                    />
                                </Title>
                            </GridItem>
                            <Divider />
                        </GridContainer>
                    </div>
                </div>
            </div>
            {/* <Container sx={{ paddingTop: 10, paddingBottom: 1, textAlign: '-webkit-center', }}>
                <PlaneTicketCardDemo
                    nombre={nombre}
                    ciudad_origen={promo.ciudad_origen}
                    codigo_ciudad={promo.codigo_ciudad}
                    precio={promo.precio}
                    imagen_principal={imagen_principal}
                    imagen_publicacion={`${FunctionsCommon.obtenerImagenDesdeItem(codigo_prestador, promo.nombre_imagen, 'publicaciones', promo.tipo_imagen, 'Prestadores')}`}
                />
            </Container> */}
            {/* <Container sx={{ paddingTop: 10, paddingBottom: 1, textAlign: '-webkit-center', }}>
                <Ticket
                    ciudad_origen={promo.ciudad_origen}
                    codigo_ciudad={promo.codigo_ciudad}
                    precio={precio}
                    imagen_principal={`${FunctionsCommon.obtenerImagenDesdeItem(codigo_prestador, nombre_imagen, 'publicaciones', tipo_imagen, 'Prestadores')}`}
                />
            </Container> */}
        </div>

    );
}
export default PromotionsPreview;
