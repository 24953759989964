import * as React from 'react';
import styled from 'styled-components'
import {
    Container,
    Rating,
    Autocomplete,
    Chip,
    IconButton,
    TextField,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Typography,
} from '@mui/material';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

import { makeStyles } from '@mui/styles';
import np from "../../assets/images/np.jpg";

import Stack from '@mui/material/Stack';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/core/ButtonUnstyled';

import { Box, Button, CircularProgress } from '@mui/material'
import { green } from '@mui/material/colors';

import { List as ListVirtual } from "react-virtualized";

import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { borderColor } from '@mui/system';

const CustomButtonRoot = styled('button')`
  background-color: none;
  padding: 15px 20px;
  border-radius: 10px;
  border: 5px;
  color: #000;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;

  &:hover {
    background-color: #EBEFFF;
  }
  

  &.${buttonUnstyledClasses.active} {
    background-color: #D1E9F6;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: 0 0 0 0 rgba(0, 127, 255, 0);
  }
`;

function CustomButton(props) {
    return <ButtonUnstyled {...props} component={CustomButtonRoot} />;
}

const obtenerImagen = prestador => {

    let arreglo = [];
    for (let i in prestador.imagen) {
        arreglo.push(prestador.imagen[i].tipo_imagen);
    }

    let index = arreglo.indexOf('P');
    if (parseInt(index) === -1) {
        return `${np}`;
    } else {
        return require(`../../assets/images/${prestador.imagen[index].nombre_imagen}`).default; //
    }
}

const Img = styled('img')({
    margin: 0,
    height: '50px',
    width: '50px',
    objectFit: 'cover',
    display: 'flex',
});

export const ContainerUI = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    justify-content: space-between;
    position: relative;
`

export default function Search(props) {

    const { clickBuscar, buscarPrestador } = props;

    const [filter, setFilter] = React.useState([]);
    const [filterEnter, setFilterEnter] = React.useState([]);

    const [chips, setChips] = React.useState([]);

    const [success, setSuccess] = React.useState(false);

    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    const onChangeAutoComplete = (value) => {


        setChips([...chips, value])

        if (value.length === 0) {
            setFilterEnter(value);
        } else {
            setFilterEnter(value);
        }

        setFilterEnter((state) => {

            const res = clickBuscar(state, props.ecosistema, props.opcioneco);
            //console.log('res click', res);
            return state;
        });
    }

    const onInputChangeAutoComplete = (value) => {

        setFilter(value);
    }

    const removeOption = (id) => {
        let busqueda = [];
        const tags = chips.slice(0)
        tags.splice(id, 1)

        setChips(tags)

        for (let i in tags) {
            busqueda.push(
                tags[i]
            )
        }

        let cadena = busqueda.toString().replace(',', ' ');

        setFilter(cadena);
        setFilter((state) => {
            const res = clickBuscar(state, props.ecosistema, props.opcioneco);
            return state;
        });
    }

    const clickBotonBuscar = () => {
        if (filter.trim().length !== 0) {
            if (chips.indexOf(filter) === -1) {
                setChips(...chips, filter)
                setChips((state) => {
                    clickBuscar(filter, props.ecosistema, props.opcioneco)
                    return state;
                });
            } else {
                return;
            }
        }
    }

    const onChange = (value) => {
        setFilter(value);
    }

    const obteniendoPromedio = (arreglo) => {
        let sumatoria = 0;
        if (arreglo.length !== 0) {
            for (let i in arreglo) {
                sumatoria = parseFloat(sumatoria) + parseFloat(arreglo[i].puntuacion);
            }
        } else {
            return 0;
        }

        let promedio = sumatoria / arreglo.length;

        return promedio.toFixed(2);
    }

    // const handleButtonClick = () => {
    //     if (!loading) {
    //         setSuccess(false);
    //         setLoading(true);
    //         timer.current = window.setTimeout(() => {
    //             setSuccess(true);
    //             setLoading(false);
    //         }, 2000);
    //     }
    // };

    const useStyles = makeStyles(() => ({
        Autocomplete: {
            '& .css-1ekq11-MuiInputBase-root-MuiFilledInput-root': {
                borderRadius: 15,
            },
            '& .css-1ds4xl6-MuiInputBase-root-MuiFilledInput-root': {
                borderRadius: 15,
            },
            '& .css-1ds4xl6-MuiInputBase-root-MuiFilledInput-root:hover:not(.Mui-disabled):before': {
                borderBottom: 0,
            },
            '& .css-1ekq11-MuiInputBase-root-MuiFilledInput-root:before': {
                borderBottom: 0,
            },
            '& .css-1ekq11-MuiInputBase-root-MuiFilledInput-root:after': {
                borderBottom: 0,
            },
            '& .makeStyles-Autocomplete-1334 .css-1ekq11-MuiInputBase-root-MuiFilledInput-root:after': {
                borderBottom: 0,
            },
            '& .css-1qhqx08-MuiAutocomplete-root .MuiFilledInput-root.MuiInputBase-sizeSmall': {
                borderBottom: 0,
            },
            '& .css-1ekq11-MuiInputBase-root-MuiFilledInput-root:hover:not(.Mui-disabled):before': {
                borderBottom: '1px solid rgba(0,0,0,0)'
            },
            '& .css-1ds4xl6-MuiInputBase-root-MuiFilledInput-root:before': {
                borderBottom: '1px solid rgba(0,0,0,0)',
            },
            '& .css-1ds4xl6-MuiInputBase-root-MuiFilledInput-root:after': {
                borderBottom: '1px solid rgba(0,0,0,0)',
            },
            '& .css-buugn7:hover:not(.Mui-disabled)::before': {
                borderBottom: '1px solid rgba(0,0,0,0)',
            },
            '& .css-buugn7::after': {
                borderBottom: '0px solid rgba(0,0,0,0)',
            },
            '& .css-buugn7::before': {
                borderRadius: 15,
                borderBottom: '1px solid rgba(0,0,0,0)',
            },
            '& .css-buugn7': {
                borderRadius: 15,
            },
            '& .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator': {
                right: 40
            },
        },
        iconButtonSearch: {
            backgroundColor: '#0056D0',
            '&:hover': {
                transform: 'scale(1.0001)',
                boxShadow: `0 1px 3px 0`,
                backgroundColor: '#0079DD',
            },
        },
        textField: {
            '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                borderColor: 'azure',
            },
            '& .jss26': {
                borderColor: 'azure',
            },
            '& .css-1p4a97y': {
                borderColor: 'azure',
            },
            borderRadius: 30,
            height: '50px',
            '& input:-internal-autofill-selected': {
                backgroundColor: "rgb(250, 241, 232)"
            },
        }
    }));

    const classes = useStyles();

    return (
        <Container
            sx={{
                position: 'relative',
                zIndex: 0,
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                margin: '0 auto',
                justifyContent: 'space-between',
                position: 'relative',
                visibility: props.visibility,
                height: props.height
            }}>
            <FormControl sx={{ m: 1, width: '100%', marginBottom: '3px', marginTop: '5px' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-search">Buscar</InputLabel>
                <OutlinedInput
                    className={classes.textField}
                    id="outlined-adornment-password"
                    type="text"
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                sx={{
                                    marginRight: '-20px'
                                }}
                            >
                                <Avatar
                                    className={classes.iconButtonSearch}
                                    onClick={() => clickBotonBuscar()}
                                    sx={{
                                        top: 0,
                                        right: 5,
                                        zIndex: 1,
                                        cursor: 'pointer',
                                    }}
                                >
                                    <SearchTwoToneIcon />
                                </Avatar>
                            </IconButton>
                        </InputAdornment>
                    }
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            if (event.target.value.trim().length !== 0) {
                                onChangeAutoComplete(event.target.value);
                            }
                        }
                    }}
                    onChange={(event, value) => {
                        onChange(event.target.value);
                    }}
                    label="Buscar"
                />
            </FormControl>
        </Container>
    );
}
