import * as React from 'react';


import logo from '../../../assets/images/logo_circular2.png'
import logo_ecosistemas from '../../../assets/images/todo.png'
import '../../../css/style.css'
//import {Button_Normal, Button_Background, ButtonWOBackground} from  '../components/Button'
import Footer from '../../components/Footer'
import CardSimple from '../../components/CardSimple'
import CardShare from '../../components/CardShare'
//import TabBasic from  '../components/TabBasic'
//import Button_Image from  '../components/Button_Image'
import Search from '../../components/Search'
import Tabs from '../../components/Tabs'
import playa from '../../../assets/images/playa.png'
import ciudad from '../../../assets/images/ciudad.png'
import llanos from '../../../assets/images/llanos.png'
import montana from '../../../assets/images/montana.png'

import promo1 from '../../../assets/images/promo1.png'
import promo2 from '../../../assets/images/promo2.png'
import promo3 from '../../../assets/images/promo3.png'
import promo4 from '../../../assets/images/promo4.png'

import patanemo from '../../../assets/images/patanemo.png'
import ocumare from '../../../assets/images/ocumare.png'
import chichiriviche from '../../../assets/images/chichiriviche.png'
import coloniatovar from '../../../assets/images/coloniatovar.png'
import { Link } from "react-router-dom";
import { Box, Container } from '@mui/material';

import ProviderService from '../../../servicio/ProviderService';
import CardProvider from '../../components/CardProvider';
import FunctionsCommon from '../../functions/FunctionsCommon'
import Header from '../../components/Header';

import PlaneTicketCardDemo from '../../components/PlaneTicketCardDemo';

import { useParams } from 'react-router-dom';

export function Promotions(props) {
    const eslogan = "    ...turismo a la carta"

    const [promos, setPromos] = React.useState([]);
    const params = useParams();

    React.useEffect(() => {
        let codigo_ciudad = "";
        let parametro = params.url.toString().replace('paquetes-turisticos-en-', '');
        console.log(parametro);
        let arreglo = [];
        arreglo = parametro.split('-');

        for (let i = 0; i < arreglo.length; i++) {
            codigo_ciudad += arreglo[i] + ' ';
        }

        console.log(codigo_ciudad);

        ProviderService
            .consultar_promociones(codigo_ciudad.toString().trimEnd())
            .then(
                (response) => {
                    console.log(response.data);
                    setPromos(response.data);
                },
                error => {
                }
            );
    }, [])

    return (
        <div>
            <Header />
            <div className="contenedor_principal">
                <Container>
                    <div className="contenedor_simple">
                        <h2>Viajes, full days y promociones destacadas</h2>
                        <div className="contenedor_tarjetas">
                            {promos.map((promo) => (
                                promo.publicaciones.length !== 0 ?
                                    <CardProvider
                                        nombre={promo.nombre}
                                        codigo_prestador={promo.codigo_prestador}
                                        imagen={`${FunctionsCommon.obtenerImagenPrincipalJSON(promo.codigo_prestador, promo.imagenes)}`}
                                        publicaciones={promo.publicaciones}
                                        comunicaciones={promo.comunicaciones}
                                    />
                                    : null

                            ))}                            
                        </div>
                    </div>
                </Container>                
            </div>
        </div>
    );
}
export default Promotions;
