import * as React from 'react';

import {
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material'

import { styled } from '@mui/material/styles';

export function Title(props) {

    const FireNav = styled(List)({
        paddingTop: 2,
        '& .MuiListItemButton-root': {
            paddingLeft: 24,
            paddingRight: 24,
        },
        '& .MuiListItemIcon-root': {
            minWidth: 0,
            marginRight: 16,
        },
        '& .MuiSvgIcon-root': {
            fontSize: 30,
        },
    });

    return (
        <FireNav component="nav" disablePadding sx={{ width: '100%' }}>
            <ListItemButton component="a" href="">
                <ListItemIcon sx={{ fontSize: 20 }}>{props.icon}</ListItemIcon>
                <ListItemText
                    sx={{ my: 0 }}
                    primary={props.titulo}
                    primaryTypographyProps={{
                        fontSize: 20,
                        fontWeight: 'medium',
                        letterSpacing: 0,
                    }}
                />
            </ListItemButton>
            <Divider />
            <ListItem component="div" disablePadding>
                {props.children}
            </ListItem>
        </FireNav>
    );
}
export default Title;
