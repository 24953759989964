import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
//import { Link as RouterLink } from 'react-router-dom';
import shareFill from '@iconify/icons-eva/share-fill';
import messageCircleFill from '@iconify/icons-eva/message-circle-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Box, Link, Card, Grid, Avatar, Typography, CardContent } from '@mui/material';
// utils
// import { fDate } from '../../../utils/formatTime';
// import { fShortenNumber } from '../../../utils/formatNumber';
//
import SvgIconStyle from './SvgIconStyle';
import shaped from '../../../../assets/images/shape-avatar.svg'
import useMediaQuery from '@mui/material/useMediaQuery';

import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
    position: 'relative',
    paddingTop: 'calc(100% * 3 / 4)'
});

const TitleStyle = styled(Link)({
    height: 44,
    overflow: 'hidden',
    WebkitLineClamp: 2,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical'
});

const AvatarStyle = styled(Avatar)(({ theme }) => ({
    zIndex: 9,
    width: 32,
    height: 32,
    position: 'absolute',
    left: theme.spacing(3),
    bottom: theme.spacing(-2)
}));

const InfoStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    color: theme.palette.text.disabled
}));

const CoverImgStyle = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'fill',
    position: 'absolute'
});

// ----------------------------------------------------------------------

// BlogPostCard.propTypes = {
//   post: PropTypes.object.isRequired,
//   index: PropTypes.number
// };

const fShortenNumber = (number) => {
    return replace(numeral(number).format('0.00a'), '.00', '');
}

const useStyles = makeStyles(() => ({
    transicion: {
        transition: '0.2s',
        '&:hover': {
            transform: 'scale(1.02)',
            boxShadow: '11px 10px 13px -6px rgba(0,0,0,0.73)',
            webkitBoxShadow: '11px 10px 13px -6px rgba(0,0,0,0.73)',
            mozBoxShadow: '11px 10px 13px -6px rgba(0,0,0,0.73)'
        },
    },
}));

const Redirigir = (url) => {
    window.open(url);
}

export default function CustomCard(props) {
    //const { imagen, imagen_principal, nombre, author } = post;

    console.log(window.location)

    const imagen = props.imagen;
    const imagen_principal = props.imagen_principal;
    const nombre = props.nombre;
    const latestPostLarge = props.latestPostLarge;
    const latestPost = props.latestPost;

    const url_prestador = props.url_prestador;
    //   const latestPostLarge = index === 0;
    //   const latestPost = index === 1 || index === 2;

    const POST_INFO = [
        { number: 1000, icon: messageCircleFill },
        { number: 2000, icon: eyeFill },
        { number: 1500, icon: shareFill }
    ];

    const m450 = useMediaQuery('(max-width:450px)');
    const classes = useStyles();

    return (
        <Grid item xs={12} sm={latestPostLarge ? 12 : 6} md={latestPostLarge ? 6 : 3}>
            <Card className={classes.transicion} sx={{ position: 'relative', width: (!m450 ? 250 : 390), maxHeight: 350 }}>
                <CardMediaStyle
                    sx={{
                        ...((latestPostLarge || latestPost) && {
                            pt: 'calc(100% * 4 / 3)',
                            '&:after': {
                                top: 0,
                                content: "''",
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
                            }
                        }),
                        ...(latestPostLarge && {
                            pt: {
                                xs: 'calc(100% * 4 / 3)',
                                sm: 'calc(100% * 3 / 4.66)'
                            }
                        })
                    }}
                >
                    <SvgIconStyle
                        color="paper"
                        src={shaped}
                        sx={{
                            width: 80,
                            height: 36,
                            zIndex: 9,
                            bottom: -15,
                            position: 'absolute',
                            ...((latestPostLarge || latestPost) && { display: 'none' })
                        }}
                    />
                    <AvatarStyle
                        alt="name"
                        src={imagen_principal}
                        sx={{
                            ...((latestPostLarge || latestPost) && {
                                zIndex: 9,
                                top: 24,
                                left: 24,
                                width: 40,
                                height: 40
                            })
                        }}
                    />

                    <CoverImgStyle
                        alt={nombre}
                        src={imagen}
                        onClick={() => {
                            Redirigir(!latestPostLarge && !latestPost ? `${window.location.pathname}/${url_prestador}` : url_prestador)
                        }}
                    />
                </CardMediaStyle>

                <CardContent
                    sx={{
                        pt: 4,
                        ...((latestPostLarge || latestPost) && {
                            bottom: 0,
                            width: '100%',
                            position: 'absolute'
                        })
                    }}
                >
                    {/* <Typography
            gutterBottom
            variant="caption"
            sx={{ color: 'text.disabled', display: 'block' }}
          >
            {fDate(createdAt)}
          </Typography> */}

                    {/* <TitleStyle                        
                        color="inherit"
                        variant="subtitle2"
                        underline="hover"
                        component={RouterLink}
                        sx={{
                            ...(latestPostLarge && { typography: 'h5', height: 60 }),
                            ...((latestPostLarge || latestPost) && {
                                color: 'common.white'
                            })
                        }}
                        onClick={() => {
                            Redirigir(`/perfil/${url_prestador}`)
                        }}
                    >
                        {nombre}
                    </TitleStyle> */}

                    <Link
                        component="button"
                        variant="subtitle2"
                        sx={{
                            ...(latestPostLarge && { typography: 'h5', height: 60 }),
                            ...((latestPostLarge || latestPost) && {
                                color: 'common.white',
                                textAlign: 'start'
                            })
                        }}
                        onClick={() => {
                            Redirigir(!latestPostLarge && !latestPost ? `${window.location.pathname}/${url_prestador}` : url_prestador)
                        }}
                    >
                        {nombre}
                    </Link>

                    <InfoStyle>
                        {POST_INFO.map((info, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    ml: index === 0 ? 0 : 1.5,
                                    ...((latestPostLarge || latestPost) && {
                                        color: 'grey.500'
                                    })
                                }}
                            >
                                <Box component={Icon} icon={info.icon} sx={{ width: 16, height: 16, mr: 0.5 }} />
                                <Typography variant="caption">{fShortenNumber(info.number)}</Typography>
                            </Box>
                        ))}
                    </InfoStyle>
                </CardContent>
            </Card>
        </Grid>
    );
}
